import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Logo from "../../../images/logo_Lutefish_app.svg";

import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    AppBar,
    Container,
    Tabs,
    Tab,
    Toolbar,
    IconButton,
    Button,
    Tooltip,
    Menu,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

//import Logo from "../../../images/logo-new-horizon-1.svg";
import Avatar from "../../../images/avatar.svg";
import { tabsClasses } from "@mui/material/Tabs";
import ArrowDown from "../../../images/arrow-down.svg";
import profile from "../../../images/user-octagon-outline.svg";
import settingsIcon from "../../../images/setting.svg";
import signout from "../../../images/signout.svg";
import Home from "../../../images/home.svg";
import Musicians from "../../../images/musicians.svg";
import Gigs from "../../../images/gigs.svg";
import Events from "../../../images/events.svg";
import Shop from "../../../images/shop.svg";
import LiveStreamDefaultBanner from "../../../images/LiveStreamDefaultBannerNew.png";
import Profile from "../../../images/profile-circle.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format, addMinutes, isBefore } from "date-fns";
import { UserInterface } from "../../../types/types";
import i18n from "../../../l10n/strings.json";
import LiveStreamInfo from "../LiveStreamInfo";
import { useUser } from "../../../context/UserContext";
import { isMobile, signOut } from "../../../utils";
import { LiveStreamParticipantsWrapper } from "../DailyStyled";
import Loader from "../../commonComponents/Loader";
import LiveStreamEnded from "../LiveStreamingDialog/LivestreamEndPage";
import { useTheme } from "@mui/material/styles";

const TabsMenu = [
    {
        label: "Home",
        id: "home",
        disabled: false,
        icon: Home,
    },
    {
        label: "Musicians",
        id: "musicians",
        disabled: false,
        icon: Musicians,
    },
    {
        label: "Gigs",
        id: "gigs",
        disabled: false,
        icon: Gigs,
    },
    {
        label: "Events",
        id: "events",
        disabled: false,
        icon: Events,
    },
    {
        label: "Shop",
        id: "shop",
        disabled: false,
        icon: Shop,
    },
    {
        label: "Support",
        id: "helpandsupport",
        disabled: false,
    },
];

const SettingsMenu = [
    {
        label: "Social Profile",
        id: "profile",
        icon: profile,
    },
    {
        label: "Settings",
        id: "settings",
        icon: settingsIcon,
    },
    {
        label: "Sign Out",
        id: "signout",
        icon: signout,
    },
];

function LiveStreamContent({ sessionDetails }: { sessionDetails: any }) {
    const navigate = useNavigate();
    let user = useUser();
    let isUserAuthenticated = user.email !== "" ? true : false;
    const [selectedTab, setSelectedTab] = useState("");
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const websocketRef = useRef<WebSocket | null>();
    const navigateTo = (path: string) => {
        navigate(path);
    };
    const theme = useTheme();


    const handleCloseUserMenu = (itemId: string) => {
        setAnchorElUser(null);
        const token = localStorage.getItem("sessionId");
        fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-device`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if (data !== null) {
                    localStorage.setItem("activeDevice", data);
                    handleNavigation(itemId, true);
                }
            })
            .catch((err) => {
                handleNavigation(itemId, false);
            });
    };

    const handleNavigation = (settingsId: string, isActiveDevice: boolean) => {
        if (websocketRef.current) {
            websocketRef.current.close();
        }
        if (settingsId === "signout") {
            signOut(user.email);
            navigate("/login");
        } else if (settingsId === "settings" && isActiveDevice) {
            navigate("/home/settings/account/account");
        } else if (settingsId === "settings" && !isActiveDevice) {
            navigate("/home/settings/account/account");
        } else if (settingsId === "profile") {
            navigate(`/socialHome/${user.nickname}`);
        }
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleChange: any = (
        event: React.SyntheticEvent<Element, Event>,
        tabId: string
    ) => {
        if (websocketRef.current) {
            websocketRef.current.close();
        }
        setSelectedTab(tabId);
        navigate(`/${tabId}`, { replace: true });
    };

    return (
        <Grid container justifyContent="center">
            <AppBar
                position="sticky"
                sx={{
                    maxHeight: { xs: "64px", sm: "80px", md: "100px" },
                    color: "#FFFFFF !important",
                    background: "linear-gradient(360deg, #5A40F5 0%, #0273E6 100%) ",
                }}
            >
                <Container maxWidth="xl" >
                    <Toolbar disableGutters>
                        <Grid container={true} columns={{ xs: 12 }}>
                            <Grid item={true} xs={3} className="center-align">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    className="appbar-logo-custom-height"
                                />
                            </Grid>
                            <Grid item={true} xs={7}>
                                <Grid
                                    container={true}
                                    columns={{ xs: 12 }}
                                    style={{
                                        flexGrow: 1,
                                        // display: "flex",
                                        // justifyContent: "center"
                                    }}
                                >
                                    <Grid
                                        item={true}
                                        xs={12}
                                        className="center-align"
                                        sx={{ borderBottom: 1, borderColor: "divider" }}
                                    >
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                display: { md: "flex", xl: "flex" },
                                                mt: 1,
                                                width: { md: "55%", xl: "48%" },
                                                paddingLeft: { xl: "10px" },
                                            }}
                                            className="appTabs"
                                        >
                                            <Tabs
                                                value={selectedTab}
                                                onChange={handleChange}
                                                indicatorColor="secondary"
                                                aria-label="Tabs"
                                                className="appBarTabs"
                                                sx={{
                                                    [`& .${tabsClasses.scrollButtons}`]: {
                                                        color: "white",
                                                        "&.Mui-disabled": { opacity: 0.3 },
                                                    },
                                                    "& .Mui-selected": {
                                                        background: theme.gradient.gradient1,
                                                        borderRadius: "18px",
                                                        textDecoration: "none",
                                                        minHeight: "36px",
                                                    },
                                                    "& .MuiTabs-indicator": {
                                                        display: "none",
                                                    },
                                                    margin: { md: "0 auto" },
                                                }}
                                            >
                                                {TabsMenu.map((tabMenu) => (
                                                    <Tab
                                                        key={tabMenu.id}
                                                        icon={
                                                            <img src={tabMenu.icon} />
                                                        }
                                                        iconPosition="start"
                                                        sx={{
                                                            minHeight: "36px",
                                                            borderRadius: "18px",
                                                            fontSize: "1rem",
                                                            maxHeight: "36px",
                                                        }}
                                                        className="iconButton"
                                                        label={
                                                            <Typography
                                                                sx={{ textTransform: "capitalize", color: "#FFFFFF", fontSize: "1rem" }}
                                                            >
                                                                {tabMenu.label}
                                                            </Typography>
                                                        }
                                                        value={tabMenu.id}
                                                        disabled={tabMenu.disabled}
                                                        disableFocusRipple
                                                        disableRipple
                                                        onClick={() => {
                                                            handleChange(null, tabMenu.id);
                                                        }}
                                                    />
                                                    // </Grid>
                                                ))}
                                            </Tabs>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={2} className="center-align">
                                {isUserAuthenticated ? (
                                    <Box sx={{ flexGrow: 0 }}>
                                        <Tooltip title="Open settings">
                                            <IconButton
                                                onClick={handleOpenUserMenu}
                                                sx={{
                                                    p: 0,
                                                    width: "150px",
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}
                                            >
                                                <img
                                                    src={user.profilePhotoUrl ? `${process.env.REACT_APP_PICTURE_URL}${user.profilePhotoUrl}` : Avatar}
                                                    alt="Profile pic"
                                                    style={{
                                                        width: 32,
                                                        height: 32,
                                                        backgroundColor: user.profilePhotoUrl === null ? "darkorange" : "",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <Typography
                                                    className="typography-field-custom-font-size"
                                                    sx={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                    }}
                                                    color="white"
                                                >
                                                    {`${user.firstName} ${user.lastName}`}
                                                </Typography>
                                                <img src={ArrowDown} alt="Down Arrow" />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            sx={{ mt: "60px" }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {SettingsMenu.map((menu) => (
                                                <ListItemButton
                                                    key={menu.label}
                                                    onClick={() => handleCloseUserMenu(menu.id)}
                                                >
                                                    <ListItemIcon>
                                                        <img src={menu.icon} alt={menu.label}
                                                            style={{
                                                                width: 32,
                                                                height: 32,
                                                            }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={menu.label}
                                                        style={{ fontSize: "1.125rem" }}
                                                    />
                                                </ListItemButton>
                                            ))}
                                        </Menu>
                                    </Box>
                                ) : (
                                    <Grid container justifyContent="center">
                                        <Button
                                            variant="text"
                                            sx={{ color: "#FFFFFF" }}
                                            onClick={() => navigateTo("/login")}
                                        >
                                            Sign In
                                        </Button>
                                        <Button
                                            variant="text"
                                            sx={{ color: "#FFFFFF", pl: "15px" }}
                                            onClick={() => navigateTo("/signUpEmail")}
                                        >
                                            Sign Up
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid item mt="40px" minWidth="50%">
                <Card>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="244px"
                        image={LiveStreamDefaultBanner}
                    />
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: "2rem", color: "#000", fontWeight: "900" }}>
                                    {sessionDetails?.sessionName}
                                </Typography>
                                <Typography variant="subtitle1" component="p" sx={{ fontSize: "14px", color: "#7A7985", fontWeight: "500", mt: 1 }}>
                                    {sessionDetails?.dateTime ? (
                                        `Starts at ${format(
                                            new Date(sessionDetails.dateTime),
                                            "h:mm aaa eeee, MM/dd/yyyy"
                                        )}`
                                    ) : (
                                        "Start time not available"
                                    )}

                                </Typography>
                            </Grid>
                            <Grid container xs={4} md={4} justifyContent={'flex-end'}>
                                <Button
                                    color="info"
                                    sx={{
                                        width: "100%",
                                        border: "1.5px solid #0273E6",
                                        borderRadius: "18px",
                                        marginTop: 1,
                                        maxWidth: "104px"
                                    }}
                                    variant="outlined"
                                    onClick={() => navigateTo("/signUpEmail")}
                                >
                                    <Typography
                                        variant="h6"
                                        component="span"
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            color: "#0273E6"
                                        }}
                                    >
                                        Sign Up
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={12} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="body3" component="h5" sx={{ fontSize: "18px", color: "#000", fontWeight: "500" }}>
                                    Musicians in this Session
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} md={12} sx={{ mt: 3 }}>
                            <LiveStreamParticipantsWrapper>
                                {sessionDetails?.attendees?.map(
                                    (attendee: UserInterface, index: any) => (
                                        <div
                                            key={attendee?.id + index}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                margin: '8px 15px 8px 0'
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={Profile}
                                                alt="user-icon"
                                                className="music-logo-custom-height"
                                            />{" "}
                                            <Typography
                                                className="attendee-field-custom-font-size"
                                                sx={{ color: "#3F77D7" }}
                                            >
                                                {" "}
                                                {`${attendee?.firstName} ${attendee?.lastName}`}{" "}
                                            </Typography>{" "}
                                        </div>
                                    )
                                )}
                            </LiveStreamParticipantsWrapper>
                        </Grid>

                        <Grid container xs={12} md={12} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h5" sx={{ fontSize: "18px", color: "#000", fontWeight: "500" }}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component="p" sx={{ fontSize: "16px", color: "#5B5B65", fontWeight: "400" }}>
                                    {sessionDetails?.sessionDescription}
                                </Typography>
                            </Grid>
                        </Grid>
                        {sessionDetails && <LiveStreamInfo session={sessionDetails} />}

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

function LiveStreamDetails() {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const sessionId = searchParams.get("id");
    const [sessionDetails, setSessionDetails] = useState<any>();
    const [isSessionLoading, setIsSessionLoading] = useState(false);
    
    useEffect(() => {
        const hasReloaded = sessionStorage.getItem("hasReloaded");
        if (sessionId && !hasReloaded) {
          sessionStorage.setItem("hasReloaded", "true"); 
          window.location.reload();
        }
        return () => sessionStorage.removeItem("hasReloaded");
    }, [sessionId]);

    useEffect(() => {
        setIsSessionLoading(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/public/session-details/${sessionId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status > 400) {
                    setIsSessionLoading(false);
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((res: any) => {
                setIsSessionLoading(false);
                const endTime = addMinutes(new Date(res.dateTime), res.duration);
                if (!res?.dailyLiveStreamStatus && isBefore(endTime, new Date())) {
                    navigate(`/liveStreamEnd?id=${sessionId}`);
                } else {
                    if (res?.dailyLiveStreamStatus) {
                        setSessionDetails(res);
                        localStorage.setItem("broadcastingInfo", JSON.stringify({
                            broadCastingSession: res
                        }));
                        // connectToDevice();
                    }
                }
            });
    }, []);

    if(isMobile) {
        return(
            <LiveStreamEnded title="Lutefish livestreams look best on a desktop browser. Swim on over to your computer to listen in!"/>
        )
    }

    return (
        <>
            {isSessionLoading ?
                <Loader /> :
                <>
                    {
                        sessionDetails && sessionDetails?.dailyLiveStreamStatus ? (
                        <LiveStreamContent sessionDetails={sessionDetails} />
                    ) : (
                        <LiveStreamEnded title={i18n.liveStream.sessionNotExists}/>
                    )}
                </>

            }
        </>

    );
}
export default LiveStreamDetails;
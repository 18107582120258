import React, { useEffect, useRef, useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Slider,
	MenuItem,
	Select,
	Button,
	Typography,
	Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import plusIcon from "../../../images/plusbpm.svg";
import minusIcon from "../../../images/minusbpm.svg";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useUser } from "../../../context/UserContext";
import {
	getAllSoundEffects,
	MetronomeSoundEffectDTO,
} from "./JamSessionFeaturesApi";

const styles = {
	dialog: {
		"& .MuiPaper-root": {
			borderRadius: "12px",
			backgroundColor: "#fff",
			width: "460px",
			height: "430px",
			padding: "10px",
		},
	},
	dialogTitle: {
		fontWeight: "500",
		fontSize: "20px",
		textAlign: "center",
		position: "relative",
	},
	closeButton: {
		position: "absolute",
		right: 8,
	},
	bpmText: {
		fontSize: "36px",
		fontWeight: "400",
		textAlign: "justify",
		color: "#C2C2C3",
	},
	sliderContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		mt: 1,
		gap: "0.5rem",
	},
	slider: {
		flexGrow: 1,
		width: "75%",
		maxWidth: "300px",
		color: "#FF6600",
	},
	label: {
		fontSize: "16px",
		mt: 2,
	},
	volumeIndicator: {
		width: "80%",
		display: "flex",
		justifyContent: "space-between",
		margin: "0 auto",
	},
	volumeText: {
		fontSize: "14px",
	},
	soundEffectSelect: {
		mt: 1,
		borderRadius: "6px",
		"& .MuiOutlinedInput-root": {
			"& fieldset": { borderColor: "#C5C5C5" },
			"&:hover fieldset": { borderColor: "#FF6600" },
			"&.Mui-focused fieldset": { borderColor: "#FF6600" },
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		mt: "15px",
	},
	actionButton: {
		width: "90%",
		height: "40px",
		color: "white",
		borderRadius: "20px",
		fontWeight: "bold",
		"&:hover": { backgroundColor: "#E65C00" },
	},
};

const MIN_BPM_VALUE = 30;
const MAX_BPM_VALUE = 252;
const DEFAULT_VOLUME = 25;

interface MetronomeDialogProps {
	open: boolean;
	onClose: () => void;
	bpm: number;
	setBpm: React.Dispatch<React.SetStateAction<number>>;
	isPlaying: boolean;
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
	metronomeSettings?: any;
	DEFAULT_BPM: number;
}

const MetronomeDialog: React.FC<MetronomeDialogProps> = ({
	open,
	onClose,
	bpm,
	setBpm,
	isPlaying,
	setIsPlaying,
	metronomeSettings,
	DEFAULT_BPM,
}) => {
	const token = localStorage.getItem("sessionId");
	const twilioSessionId = localStorage.getItem("twilioSessionId") ?? "";
	const user = useUser();
	const [volume, setVolume] = useState(metronomeSettings?.metronome_volume || DEFAULT_VOLUME);
	const [soundEffect, setSoundEffect] = useState(metronomeSettings?.metronome_effect || "");
	const [loading, setLoading] = useState(false);
	const [metronomeSoundEffects, setMetronomeSoundEffects] = useState<
		MetronomeSoundEffectDTO[]
	>([]);

	useEffect(() => {
		if (!token) return;
		getAllSoundEffects(token)
			.then(setMetronomeSoundEffects)
			.catch((error) => console.error("Error fetching sound effects:", error));
	}, []);


	const handleMetronome = (payload: any) => {
		if (payload?.metadata?.created_by === user?.deviceId) {
			setLoading(false);
			if (payload?.metadata?.metronome_effect === "on") {
				setIsPlaying(true);
			} else {
				setIsPlaying(false);
				setBpm(DEFAULT_BPM);
				setVolume(DEFAULT_VOLUME);
			}
		}
	};

	const handleMetronomePlay = (
		action: string,
		newBpm: number = 0,
		callByBtn: boolean = false,
		updatedSoundEffect: string = soundEffect
	) => {
		if (!isPlaying && action === "UPDATE") return;
		const newBpmValue = callByBtn ? newBpm : bpm;
		const metronomeMessage = {
			action: "metronome",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			metronomeEnabled: action !== "DISABLE",
			bpmSetting: action === "DISABLE" ? 0 : newBpmValue,
			metronomeVolume: action === "DISABLE" ? 0 : volume,
			metronomeSoundEffect:
				action === "DISABLE" ? null : updatedSoundEffect,
		};
		
		WebsocketClientUSerStatus.handleMetronome(metronomeMessage);
		setLoading(true);
	};


	useEffect(() => {
		WebsocketClientUSerStatus.on("metronome", handleMetronome);

		return () => {
			WebsocketClientUSerStatus.off("metronome", handleMetronome);
		};
	});

	const timeoutIdRef = useRef<number | null>(null);

	const debouncedHandleMetronomePlay = (value:number) => {
		if (timeoutIdRef.current) {
		clearTimeout(timeoutIdRef.current);
		}

		timeoutIdRef.current = window.setTimeout(() => {
		handleMetronomePlay("UPDATE", value, true);
		}, 500);
	};

	const increaseBpm = () => {
		const newBpm = bpm < MAX_BPM_VALUE ? bpm + 1 : bpm;
		setBpm(newBpm);
		debouncedHandleMetronomePlay(newBpm);
	};

	const decreaseBpm = () => {
		const newBpm = bpm > MIN_BPM_VALUE ? bpm - 1 : bpm;
		setBpm(newBpm);
		debouncedHandleMetronomePlay(newBpm);
	};
  
	const handleSoundEffectChange = (value: string) => {
		setSoundEffect(() => {
			const updatedEffect = value;
			handleMetronomePlay("UPDATE", 0, false, updatedEffect);
			return updatedEffect;
		});
	  };			
	
	return (
		<Dialog open={open} onClose={onClose} maxWidth="xs" sx={styles.dialog}>
			<DialogTitle sx={styles.dialogTitle}>
				Metronome
				<IconButton onClick={onClose} sx={styles.closeButton}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={{ overflowY: "hidden" }}>
				<Typography
					sx={{ ...styles.bpmText, color: isPlaying ? "#000" : "#C2C2C3" }}
				>
					{bpm}{" "}
					<span
						style={{
							fontSize: "14px",
							fontWeight: "500",
							color: isPlaying ? "#000" : "#C2C2C3",
						}}
					>
						BPM
					</span>
				</Typography>

				<Box sx={styles.sliderContainer}>
					<IconButton onClick={decreaseBpm}>
						<img src={minusIcon} alt="Decrease BPM" width={24} height={24} />
					</IconButton>

					<Slider
						value={bpm}
						onChange={(_, value) => setBpm(value as number)}
						onChangeCommitted={() => handleMetronomePlay("UPDATE")}
						min={MIN_BPM_VALUE}
						max={MAX_BPM_VALUE}
						sx={styles.slider}
						valueLabelDisplay="auto"
					/>

					<IconButton onClick={increaseBpm}>
						<img src={plusIcon} alt="Increase BPM" width={24} height={24} />
					</IconButton>
				</Box>

				<Box sx={styles.volumeIndicator}>
					<Typography sx={styles.volumeText}>{MIN_BPM_VALUE}</Typography>
					<Typography sx={styles.volumeText}>{MAX_BPM_VALUE}</Typography>
				</Box>

				<Typography sx={styles.label}>Volume</Typography>
				<Box sx={styles.sliderContainer}>
					<Slider
						value={volume}
						onChange={(_, value) => setVolume(value as number)}
						onChangeCommitted={() => handleMetronomePlay("UPDATE")}
						min={0}
						max={100}
						sx={styles.slider}
						valueLabelDisplay="auto"
					/>
				</Box>

				<Box sx={styles.volumeIndicator}>
					<Typography sx={styles.volumeText}>0</Typography>
					<Typography sx={styles.volumeText}>100</Typography>
				</Box>

				<Typography sx={styles.label}>Sound effects</Typography>
				<Select
					fullWidth
					value={soundEffect}
					onChange={(e) => handleSoundEffectChange(e.target.value)}
					displayEmpty
					sx={styles.soundEffectSelect}
					renderValue={(selected) =>
						metronomeSoundEffects.find(
							({ soundEffectFileName }) => soundEffectFileName === selected
						)?.soundEffectName ?? "Choose one"
					}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: 3 * 38, 
								overflowY: "auto",
							},
						},
					}}
				>
					{metronomeSoundEffects.map((option) => (
						<MenuItem
							key={option.id}
							title={option.soundEffectName}
							value={option.soundEffectFileName}
						>
							{option.soundEffectName}
						</MenuItem>
					))}
				</Select>

				<Box sx={styles.buttonContainer}>
					<Button
						variant="contained"
						sx={{
							...styles.actionButton,
							backgroundColor: isPlaying ? "#EB2A00" : "#FF6600",
						}}
						disabled={loading || !soundEffect}
						onClick={() => handleMetronomePlay(isPlaying ? "DISABLE" : "ENABLE")}
					>
						{isPlaying ? "⏸ Stop" : "▶ Start"}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default MetronomeDialog;
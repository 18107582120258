import {
	Card,
	CardContent,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import React, { useEffect } from "react";
import { useUser } from "../context/UserContext";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import LutefishSwitch from "../ui/LutefishSwitch";

export const NotificationSettings = () => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const [state, setState] = React.useState<any>({
		newConnectionRequest: false,
		doesSomeoneCommentedOrLiked: false,
		doesSomeoneMessagedToMe: false,
		isweeklysummary: true,
	});

	useEffect(() => {
		getDefaultConfiguration();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDefaultConfiguration = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/get_email-notification-settings`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setState({
					...state,
					newConnectionRequest: data.newConnectionRequests,
					doesSomeoneCommentedOrLiked: data.newCommentsOrLikes,
					doesSomeoneMessagedToMe: data.newMessage,
					isweeklysummary: data.weeklySummary
				});
			})
			.catch((err) => {});
	};

	const handleConnectionReqChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		notificationType: string,
		stateKey: string
	) => {
		setState({
			...state,
			[stateKey]: !state[stateKey],
		});

		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/update-email-notification-preferences`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					[notificationType]: state[stateKey] ? false : true,
				}),
			}
		).then((response) => {
			if (response.status >= 400) {
				throw new Error();
			}
		});
	};

	// const handleArtistsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setState({
	// 		...state,
	// 		doesLocalArtistsJoins: !state.doesLocalArtistsJoins,
	// 	});
	// };

	return (
		<>
			<Grid container sx={{ marginBottom: "20px" }}>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card>
						<CardContent>
							<Typography
								sx={{
									fontSize: "24px",
									display: "flex",
									alignItems: "center",
									fontWeight: "500",
									fontFamily: "Lexend",
									lineHeight: "30px",
									marginBottom: 2,
								}}
								color="#000000"
							>
								Email Notifications  Preferences
							</Typography>
							<FormGroup sx={{ marginBottom: 2 }}>
								<FormControlLabel
									control={
										<LutefishSwitch
											checked={state.newConnectionRequest}
											onChange={(e: any) =>
												handleConnectionReqChange(
													e,
													"connectionReq",
													"newConnectionRequest"
												)
											}
											sx={{ m: 1 }}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Connection Requests
										</Typography>
									}
								/>
								<FormControlLabel
									control={
										<LutefishSwitch
											sx={{ m: 1 }}
											checked={state.doesSomeoneCommentedOrLiked}
											onChange={(e: any) =>
												handleConnectionReqChange(
													e,
													"postActivities",
													"doesSomeoneCommentedOrLiked"
												)
											}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Comments or Likes
										</Typography>
									}
								/>
								
								<FormControlLabel
									control={<LutefishSwitch sx={{ m: 1 }} />}
									checked={state.doesSomeoneMessagedToMe}
									onChange={(e: any) =>
										handleConnectionReqChange(
											e,
											"message",
											"doesSomeoneMessagedToMe"
										)
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Message
										</Typography>
									}
								/>
							</FormGroup>
						</CardContent>
						<CardContent>
							<Grid container>
								<Grid item={true} xs={10}>
									<Typography
										sx={{
											fontSize: "18px",
											fontWeight: 500,
											lineHeight: "30px",
											fontFamily: "Lexend",
											color: "#000000",
											marginBottom: "5px",
										}}
									>
										Weekly Email Summary
									</Typography>
								</Grid>
								<Grid item={true} xs={2}>
									<FormControlLabel
										control={<LutefishSwitch sx={{ m: 1 }} />}
										checked={state.isweeklysummary}
										onChange={(e: any) =>
											handleConnectionReqChange(
												e,
												"weeklySummary",
												"isweeklysummary"
											)
										}
										label=""
									/>
								</Grid>
							</Grid>
							
									<FormGroup sx={{ marginBottom: 1 }}>
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												mb: 1
											}}
										>
											Like/Comments on Posts
										</Typography>
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												mb: 1
											}}
										>
											Connection Request
										</Typography>
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												mb: 1
											}}
										>
											Local Artists Join
										</Typography>

										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
												mb: 1
											}}
										>
											New Gig in My City
										</Typography>

										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											New Event Near Me
										</Typography>
									</FormGroup>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

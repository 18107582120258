import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import RightAarrow from "../../images/right arrow.svg";
import { useEffect, useState } from "react";
import {
    DatePicker,
    LocalizationProvider,
    TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { checkIsValidDate, convertDateTz } from "../../utils";
import { useTheme } from "@mui/material/styles";
import StartTimePicker from "../jam-session/StartTimePicker";
import { Constants } from "../../services/constants";
import BannerImage from "../commonComponents/UploadBannerImage";
import { addMinutes, lightFormat } from "date-fns";
import { useUser } from "../../context/UserContext";
import ImageCropper from "../../pages/ImageCropper";
import Close from "../../images/deleteCover.svg";
import CloseIcon from "../../images/close.svg";
import TimePickerCmp from "../jam-session/TimePickerCmp";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import useLocationAutocomplete from "../../hooks/useLocationAutocomplete/useLocationAutocomplete";
import { zonedTimeToUtc } from "date-fns-tz";
import validator from "validator";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import { useAlertStatus } from "../../context/AlertStatusContext";
import GoogleLocationService from "../GoogleLocationService";
import CreatePostNew from "./CreatePostNew";

const useStyles = makeStyles((theme: any) => ({
    root: {
        color: "white !important",
        fontFamily: "Lexend !important",
        "& .MuiAutocomplete-tag": {
            backgroundColor: "#0273E6",
            color: "white",
            "&:hover": {
                backgroundColor: "#0273E6",
                color: "white",
            },
        },
        "& .MuiSvgIcon-root": {
            color: "white !important",
        },
        "& .MuiAutocomplete-clearIndicator": {
            "&:hover": {
                backgroundColor: "#7A7985 !important",
            },
            backgroundColor: "#7A7985 !important",
            height: "18px",
            width: "18px",
        },
    },
}));

const genres = [
    { title: "Americana" },
    { title: "Avant Garde" },
    { title: "Baroque" },
    { title: "Big Band" },
    { title: "Bluegrass" },
    { title: "Blues" },
    { title: "Bollywood" },
    { title: "Broadway/Showtunes" },
    { title: "Chamber-Music" },
    { title: "Classic Rock" },
    { title: "Classical" },
    { title: "Contemporary Classical" },
    { title: "Country" },
    { title: "Disco" },
    { title: "Drumline" },
    { title: "EDM" },
    { title: "Electronic-Dance" },
    { title: "Folk" },
    { title: "Funk" },
    { title: "Genere" },
    { title: "Gospel" },
    { title: "Hip-hop-Rap" },
    { title: "House" },
    { title: "Indie-Alternative" },
    { title: "Jazz" },
    { title: "K-Pop" },
    { title: "Latin" },
    { title: "Metal" },
    { title: "Minimalism" },
    { title: "New Age" },
    { title: "Opera" },
    { title: "Orchestral" },
    { title: "Pop" },
    { title: "Punk" },
    { title: "R&B" },
    { title: "Ragtime" },
    { title: "Reggae" },
    { title: "Reggaeton" },
    { title: "Religious" },
    { title: "Rock" },
    { title: "Romantic" },
    { title: "Salsa" },
    { title: "Ska" },
    { title: "Soul" },
    { title: "Soundtrack" },
    { title: "Swing" },
    { title: "Symphonic" },
    {title: "Techno" },
    { title: "Wind Ensemble" },
];

const PostEvent = (props: any) => {
    const theme = useTheme();
    const durationList = Constants.duration;
    const token = localStorage.getItem("sessionId");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const user = useUser();
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchString, setSearchString] = useState("");
    const cities = useLocationAutocomplete(searchString);
    const [eventTitle, setEventTitle] = useState<any>("");
    const [ticketPrice, setTicketPrice] = useState<any>();
    const [eventDetails, setEventDetails] = useState("");
    // const[eventAddress, setEventAddress] = useState<any>(null);
    const [cityCountry, setCityCountry] = useState(user.location);
    const [venuStreetLocality, setVenuStreetLocality] = useState("");
    const [eventTicketLink, setEventTicketLink] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [endsAt, setEndsAt] = useState<Date | null | string>(
        addMinutes(new Date(), 30)
    );
    const [endDate, setEndDate] = useState<Date | null>(
        addMinutes(new Date(), 30)
    );
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [duration, setDuration] = useState<number>(30);
    const [timezone, setTimezone] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showAdvanceView, setShowAdvanceView] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxTime, setMaxTime] = useState<Date | null>(
        addMinutes(new Date(), 360)
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [minTime, setMinTime] = useState<Date | null>(
        addMinutes(new Date(), 30)
    );
    const [genresValue, setGenresValue] = useState([]);
    const [errorDate, setErrorDate] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [postOpen, setPostOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [event, setEvent] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    const [thumbnailImagePreviewUrl, setThumbnailImagePreviewUrl] =
        useState<any>("");
    const [thumbnailImgFile, setThumbnailImgFile] = useState<any>("");
    const [thumbnailResult, setThumbnailResult] = useState("");
    const [thumbnailOpen, setThumbnailOpen] = useState(false);
    const [openThumbnailImgDeleteDialog, setOpenThumbnailImgDeleteDialog] =
        useState(false);

    const [bannerImagePreviewUrl, setBannerImagePreviewUrl] = useState<any>("");
    const [bannerImgFile, setBannerImgFile] = useState<any>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [validationError, setValidationError] = useState<string>("");
    const [validationMessageTitle, setValidationMessageTitle] =
        useState<string>("");
    const [validationMessageDetails, setValidationMessageDetails] =
        useState<string>("");
    const [validationMessageVenue, setValidationMessageVenue] = useState<string>("");
    const [validationMessageAddress, setValidationMessageAddress] = useState<string>("");

    const [validationTitle, setValidationTitle] = useState(false);
    const [validationDetails, setValidationDetails] = useState(false);
    const [validationVenue, setValidationVenue] = useState(false);
    const [validationAddress, setValidationAddress] = useState(false);
    const [validationTicketUrl, setValidationTicketUrl] = useState(false);
    const [validationTicketUrlMessage, setValidationValidationTicketUrlMessage] =
        useState<string>("");

    const [ticketPriceType, setTicketPriceType] = useState<any>("free");
    const [address, setAddress] = useState("");
    const { updateAlertStatus } = useAlertStatus();

    const handleSearchStringChange = (event: any) => {
        setSearchString(event.target.value);
    };

    const [showAlert, setShowAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [lat, setLat] = useState<Number>(0);
    const [longi, setLongi] = useState<Number>(0);

    function getLocationLatLng(
        location: string
    ): Promise<{ lat: number; lng: number }> {
        return fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                location
            )}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(
                        `Geocoding API request failed with status: ${response.status}`
                    );
                }
            })
            .then((data) => {
                if (data.status === "OK") {
                    const { lat, lng } = data.results[0].geometry.location;
                    const result = { lat, lng };
                    setLat(result.lat);
                    setLongi(result.lng);
                    return result;
                } else {
                    throw new Error("Geocoding API request failed");
                }
            })
            .catch((error) => {
                console.error("Error during geocoding request:", error);
                throw error;
            });
    }

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        if (inputText.length <= 50) {
            setEventTitle(inputText);
            setValidationMessageTitle("");
            setValidationTitle(false);
        } else {
            setValidationTitle(true);
            setValidationMessageTitle("Maximum 50 characters are allowed.");
        }
    };

    const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        if (inputText.length <= 3000) {
            setEventDetails(inputText);
            setValidationMessageDetails("");
            setValidationDetails(false);
        } else {
            setValidationDetails(true);
            setValidationMessageDetails("Maximum 3000 characters are allowed.");
        }
    };
    const getMinStartTime = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    };

    const handleClose = (event: any, reason: string) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setEvent("");
        setValidationDetails(false);
        setValidationTitle(false);
        setValidationVenue(false);
        props.handleEventClose();
    };

    const handleTicketLinkChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputText = event.target.value;
        if (inputText.length > 0) {
            if (validator.isURL(inputText)) {
                setValidationValidationTicketUrlMessage("");
                setValidationTicketUrl(false);
            } else {
                setValidationTicketUrl(true);
                setValidationValidationTicketUrlMessage("Is Not Valid URL");
            }
            setEventTicketLink(inputText);
        } else if (inputText.length === 0) {
            setValidationTicketUrl(false);
            setEventTicketLink("");
        }
    };

    //set initial timezone
    useEffect(() => {
        const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimezone(
            Constants.timezoneList.find((tz) => tz.value === currTimezone) ??
            Constants.timezoneList[0]
        );
    }, []);

    //set Start Date
    useEffect(() => {
        if (timezone) {
            if (props.eventType === "modifypost" && props.eventDetails) {
                // Set start time from data coming from backend for modifyEvent
                setStartDate(new Date(props.eventDetails.startDateTime));
            } else {
                // Set default start time for non-modifyEvent
                let _startDate = new Date().toLocaleString("en-US", {
                    timeZone: timezone.value,
                });
                const reminder = 30 - (new Date(_startDate).getMinutes() % 30);
                setStartDate(addMinutes(new Date(_startDate), reminder));
            }
        }
    }, [timezone, props.eventType, props.eventDetails]);

    //send ends at
    useEffect(() => {
        if (startDate !== null) {
            const _endDate = addMinutes(new Date(startDate), duration);
            if (checkIsValidDate(_endDate)) {
                setEndDate(_endDate);

                setEndsAt(
                    _endDate.toLocaleTimeString("en-US", {
                        day: "numeric",
                        year: "numeric",
                        month: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    })
                );
            }
        }
    }, [startDate, duration, showAdvanceView]);

    useEffect(() => {
        if (ticketPriceType === "free") {
            setTicketPrice(0);
        }
    }, [ticketPriceType]);

    const ArrayOfGenres: string = genresValue
        ? Array.prototype.map.call(genresValue, (item: any) => item.title).join(",")
        : "";

    const createNewEvent = () => {
        // setIsLoading(true);
        if (cityCountry && cityCountry.trim() !== "") {
        getLocationLatLng(cityCountry).then((result: any)=>{
            setLat(result.lat);
            setLongi(result.lng)
        })
     }
        const eventStartDate = zonedTimeToUtc(
            startDate as Date,
            timezone.value as string
        ).toISOString();
        let eventEndDate = zonedTimeToUtc(
            endDate as Date,
            timezone.value as string
        ).toISOString();
        const tz =
            timezone !== undefined && timezone !== null
                ? timezone
                : setTimezone(
                    Constants.timezoneList.find(
                        (tz) =>
                            tz.value === Intl.DateTimeFormat().resolvedOptions().timeZone
                    ) ?? Constants.timezoneList[0]
                );

        let cost = ticketPriceType === "free" ? 0 : ticketPrice;

        let add = venuStreetLocality + " # " + address;

        // if (props.eventType === "post") {
        const result = {
            type: "EVENT",
            title: eventTitle,
            description: eventDetails,
            cost: cost,
            cityCountry: cityCountry,
            venue: add,
            startDateTime: eventStartDate,
            endDateTime: eventEndDate,
            timezone: tz.value,
            duration: duration.toString(),
            ticketLink: eventTicketLink,
            requirements: "",
            instruments: "",
            thumbnail: thumbnailImgFile,
            banner: bannerImgFile,
            bannerUrl: bannerImgFile && URL.createObjectURL(bannerImgFile),
            thumbnailUrl : thumbnailImgFile && URL.createObjectURL(thumbnailImgFile) ,
            genres: ArrayOfGenres,
            latitude: lat.toLocaleString(),
            longitude: longi.toLocaleString(),
        };

        if (props.eventType === "modifypost") {
			const eventDetails = props.eventDetails;
			if(!result.bannerUrl){
				result.banner = eventDetails.banner;
				result.bannerUrl = eventDetails.bannerUrl;
			}
			if(!result.thumbnailUrl){
				result.thumbnail = eventDetails.thumbnail;
				result.thumbnailUrl = eventDetails.thumbnailUrl;
			}

		}


        props.setEvent(result);
        props.handleEventClose()

    };
    const getMaxDate = () => {
        if (startDate !== null) {
            return addMinutes(startDate, 360);
        }
    };

    const getMinDate = () => {
        if (startDate !== null) {
            return addMinutes(startDate, 30);
        }
    };
    // Thumbnail image
    const handleThumbnailOpenEditor = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];
        const maxSizeInMB = 4;
		e.target.value = null;
		if (file.size > maxSizeInMB * 1024 * 1024) {
			updateAlertStatus(
				true,
				false,
				`File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
			);
			return;
		}
        setThumbnailResult(URL.createObjectURL(file));
        setThumbnailImgFile(file);
        setThumbnailOpen(true);
    };
    const deleteThumbnailUpload = () => {
        setThumbnailImagePreviewUrl("");
        setOpenThumbnailImgDeleteDialog(false);
    };
    const thumbnailUpload = (destFile: any) => {
        // e.preventDefault();
        const reader = new FileReader();
        const file = destFile;
        reader.onloadend = () => {
            setThumbnailImgFile(file);
            setThumbnailImagePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
    };
    const handlethumbnailUploadClose = () => {
        setThumbnailOpen(false);
    };
    const handleCloseThumbnailDeleteDialog = () => {
        setOpenThumbnailImgDeleteDialog(false);
    };
    const setBannerFile = (file: any) => {
        setBannerImgFile(file);
    };

    useEffect(() => {
        if (props.eventType === "modifypost") {
            const eventDetails = props.eventDetails;

            setEventTitle(eventDetails.title);
            setTicketPrice(eventDetails.cost);
            setEventDetails(eventDetails.description);
            setCityCountry(eventDetails.cityCountry);
            // setVenuStreetLocality(eventDetails.venue);
            const a =
                eventDetails.venue === "" || eventDetails.venue === null
                    ? ""
                    : eventDetails.venue;
            const [venuStreetLocality, address] = a.split(" # ");
            setVenuStreetLocality(venuStreetLocality);
            setAddress(address);
            setEventTicketLink(eventDetails.ticketLink);
            setTicketPriceType(eventDetails.cost === 0 ? "free" : "paid");
            setStartDate(
                new Date(
                    convertDateTz(
                        new Date(eventDetails?.startDateTime),
                        eventDetails?.timezone
                    )
                )
            );
            setEndDate(
                new Date(
                    convertDateTz(
                        new Date(eventDetails?.endDateTime),
                        eventDetails?.timezone
                    )
                )
            );
            setDuration(eventDetails.duration);
            setBannerImgFile(eventDetails.banner);
            setThumbnailImgFile(eventDetails.thumbnail);

            setTimezone(
                Constants.timezoneList.find(
                    (tz) => tz.value === props.eventDetails?.timezone
                ) ?? props.eventDetails?.timezone
            );

            if(eventDetails.thumbnailUrl === null){
                setThumbnailImagePreviewUrl(`${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-thumbnail.svg`)
            }else{
                setThumbnailImagePreviewUrl(eventDetails.thumbnailUrl && eventDetails.thumbnailUrl.includes("https://")
                ? eventDetails.thumbnailUrl
                :`${process.env.REACT_APP_PICTURE_URL}${eventDetails.thumbnailUrl}`)
            }
            // setBannerImagePreviewUrl(
            //     process.env.REACT_APP_PICTURE_URL + eventDetails.bannerUrl
            // );


            if(eventDetails.bannerUrl === null){
                setBannerImagePreviewUrl(`${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-banner.png`)
            }else{
                setBannerImagePreviewUrl(eventDetails.bannerUrl && eventDetails.bannerUrl.includes("https://")
                ? eventDetails.bannerUrl
                :`${process.env.REACT_APP_PICTURE_URL}${eventDetails.bannerUrl}`)
            }
            setBannerImgFile(eventDetails.banner)

            setThumbnailImgFile(eventDetails.thumbnail)

            const prevGenres = eventDetails.genres;
            const resultArray = eventDetails.genres
                ? prevGenres.split(",").map((item: any) => ({ title: item.trim() }))
                : [];
            setGenresValue(resultArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlePostClose = () => {
        setPostOpen(false);
        navigate("/home");
    };
    useEffect(() => {
        if (props.open) {
            setTimeout(() => {
                const inputField = document.getElementById(
                    "myInput"
                ) as HTMLInputElement | null;
                if (inputField) {
                    inputField.focus();
                }
            }, 500);
        }
    }, [props.open]);

    const handleChange = (
        event: React.ChangeEvent<{ value: unknown }> | SelectChangeEvent<string>
    ) => {
        setTicketPriceType(event.target.value as string);
        setTicketPrice(event.target.value === "free" ? 0 : 0); // Set to 0 for "free", empty for "paid"
    };

    const handleVenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        if (inputText.length <= 255 || inputText.length === 0) {
            setValidationVenue(false);
            setVenuStreetLocality(inputText);
            setValidationMessageVenue("");
        } else {
            setValidationVenue(true);
            setVenuStreetLocality(inputText);
            setValidationMessageVenue("Maximum 255 characters are allowed.");
        }
    };


    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        if (inputText.length <= 255 || inputText.length === 0) {
            setValidationAddress(false);
            setAddress(inputText);
            setValidationMessageAddress("");
        } else {
            setValidationAddress(true);
            setAddress(inputText);
            setValidationMessageAddress("Maximum 255 characters are allowed.");
        }
    };

    const isDisabled = () => {
        if (
            eventTitle === "" ||
            isNaN(ticketPrice) ||
            ticketPrice === "" ||
            cityCountry === "" ||
            genresValue.length === 0 ||
            validationDetails ||
            validationTitle ||
            validationVenue ||
            validationAddress ||
            isLoading
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleKeyDown = (event: any) => {
        // Prevent typing space in the password field
        if (event.key === ' ') {
            event.preventDefault();
        }
    };

    // useEffect(() => {
    //     getLocationLatLng(cityCountry).then((result) => {
    //         setLat(result.lat);
    //         setLongi(result.lng);
    //     });
    // }, [cityCountry]);

    return (
        <>
            <Grid
                className="center-align homeTab"
                style={{ padding: "10px", position: "relative" }}
                id="home-alert-title-bar"
            >
                {showAlert && (
                    <Snackbar
                        sx={{
                            "&.MuiSnackbar-anchorOriginTopCenter": {
                                top: "80px",
                            },
                        }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={showAlert}
                        autoHideDuration={6000}
                        onClose={() => setShowAlert(false)}
                    >
                        <Alert
                            onClose={() => setShowAlert(false)}
                            icon={
                                alertStatus ? (
                                    <img src={SuccessIcon} alt="error-icon" />
                                ) : (
                                    <img
                                        src={ErrorIcon}
                                        alt="error-icon"
                                        style={{ marginRight: "10px" }}
                                    />
                                )
                            }
                            sx={{
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                                borderRadius: "6px",
                                fontSize: "1.125rem",
                                fontWeight: "500",
                                color: "black",
                            }}
                        >
                            <AlertTitle
                                sx={{
                                    fontSize: "1.125rem",

                                    fontWeight: "500",
                                    color: "#000000",
                                    display: "flex",

                                    margin: "auto",
                                    padding: "4px",
                                    justifyContent: "center !important",
                                }}
                            >
                                {alertMsg}
                            </AlertTitle>
                        </Alert>
                    </Snackbar>
                )}
            </Grid>
            <Dialog
                sx={{
                    border: 1,
                    borderColor: "customColor.gray4",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px",
                }}
                fullWidth
                maxWidth="md"
                onClose={handleClose}
                open={props.open}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        component="div"
                        color="customColor.black"
                        sx={{ fontSize: "24px", lineHeight: "30px" }}
                    >
                        {props.eventType === "post" ? "Post New Event" : "Modify Event"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container xs={12}>
                        <FormControl sx={{ width: "100%" }}>
                            <Grid xs={12}>
                                <FormHelperText id="outlined-weight-helper-text">
                                    <Typography variant="body4" color="customColor.label">
                                        {i18n.events.postNewEvent.eventPageBanner}
                                    </Typography>
                                </FormHelperText>
                            </Grid>
                            <Grid
                                item
                                sx={{ width: "719px", height: "181px", borderRadius: "12px" }}
                            >
                                {/* Upload Banner image coode */}
                                <BannerImage
                                    imgFile={setBannerFile}
                                    // bannerPreviewUrl={
                                    // 	props.eventType === "modifyEvent"
                                    // 		? process.env.REACT_APP_PICTURE_URL +
                                    // 		  props.eventDetails.bannerUrl
                                    // 		: bannerImagePreviewUrl
                                    // }
                                    bannerPreviewUrl={bannerImagePreviewUrl}
                                    type={"event"}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <FormHelperText id="outlined-weight-helper-text">
                                    <Typography variant="body4" color="customColor.label">
                                        {i18n.events.postNewEvent.eventThumbnailInSearch}
                                    </Typography>
                                </FormHelperText>
                            </Grid>
                            <Grid
                                container
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <Grid xs={3} sx={{ m: 0.5 }}>
                                    {/* Upload image Code */}
                                    <Card
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            flexGrow: 1,
                                            position: "relative",
                                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <label
                                            id="photo-upload"
                                            title="Upload Photo"
                                            style={{
                                                position: "absolute",
                                                top: "95px",
                                                left: "25px",
                                                cursor: "pointer",
                                                zIndex: 2,

                                                padding: " 5px 20px",
                                                backgroundColor: "#fff",
                                                border: "1.5px solid #0273E6",
                                                color: "#0273E6",
                                                borderRadius: "15px",
                                            }}
                                        >
                                            {" "}
                                            Upload
                                            {/* <img
                                        id="photo-upload"
                                        style={{ height: '32px', width: '32px', backgroundColor: 'black' }}
                                        src={EditIcon}
                                        alt="Upload"
                                    /> */}
                                            <input
                                                id="photo-upload"
                                                type="file"
                                                accept =".jpg, .jpeg, .png, .gif"
                                                style={{ display: "none" }}
                                                onChange={(e) => handleThumbnailOpenEditor(e)}
                                            />
                                        </label>
                                        {/* {thumbnailImagePreviewUrl && props.eventDetails?.thumbnailUrl && ( */}
                                        {thumbnailImgFile && 
                                            props.eventDetails?.thumbnailUrl && (
                                            <IconButton
                                                onClick={() => setOpenThumbnailImgDeleteDialog(true)}
                                                sx={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    right: "5px",
                                                    cursor: "pointer",
                                                    zIndex: 2,
                                                }}
                                            >
                                                <img
                                                    src={Close}
                                                    alt="Close"
                                                    style={{
                                                        height: "32px",
                                                        width: "32px",
                                                        backgroundColor: "black",
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                        <CardMedia
                                            sx={{ height: "181px", backgroundColor: "#727272" }}
                                            image={
                                                thumbnailImagePreviewUrl
                                                    ? thumbnailImagePreviewUrl
                                                    : process.env.REACT_APP_PICTURE_URL +
                                                    "/defaultimages/event-thumbnail.svg"
                                            }
                                        />
                                    </Card>
                                    {openThumbnailImgDeleteDialog && (
                                        <Dialog
                                            fullWidth
                                            maxWidth="sm"
                                            open={openThumbnailImgDeleteDialog}
                                            onClose={() => handleCloseThumbnailDeleteDialog()}
                                        >
                                            <DialogTitle
                                                className="edit-heading-name"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="close dialogbox"
                                                        onClick={() => handleCloseThumbnailDeleteDialog()}
                                                        onMouseDown={(
                                                            event: React.MouseEvent<HTMLButtonElement>
                                                        ) => event.preventDefault()}
                                                        edge="end"
                                                        sx={{
                                                            marginLeft: "90%",
                                                            position: "absolute",
                                                            top: "8px",
                                                        }}
                                                    >
                                                        <img src={CloseIcon} alt="close" />
                                                    </IconButton>
                                                </Box>
                                                <Typography>Delete Image</Typography>
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    Are you sure?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleCloseThumbnailDeleteDialog()}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => deleteThumbnailUpload()}
                                                >
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}

                                    <ImageCropper
                                        open={thumbnailOpen}
                                        onClose={handlethumbnailUploadClose}
                                        imagePreviewUrl={thumbnailResult}
                                        setImagePreviewUrl={setThumbnailImagePreviewUrl}
                                        setResult={setThumbnailImgFile}
                                        post={true}
                                        photoUpload={thumbnailUpload}
                                        cover={false}
                                    />
                                </Grid>
                                <Grid container direction={"column"} xs={8}>
                                    <Grid item xs={5}>
                                        <Typography variant="body4" color="customColor.label">
                                            {i18n.events.postNewEvent.eventTitle}
                                            <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                        <Grid sx={{ mt: "9px", mb: "20px" }}>
                                            <TextField
                                                id="myInput"
                                                inputProps={{
                                                    style: {
                                                        background: "#F8F8F8",
                                                        color: "#000000",
                                                        fontSize: "1rem",
                                                        fontWeight: "500",
                                                        lineHeight: "20px",
                                                    },
                                                    autoFocus: true,
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                value={eventTitle}
                                                onChange={handleTitleChange}
                                                size="small"
                                                required
                                            />
                                            {validationMessageTitle && (
                                                <Typography
                                                    variant="caption"
                                                    color="error"
                                                    sx={{ float: "right" }}
                                                >
                                                    {validationMessageTitle}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item container={true} columns={{ xs: 12 }}>
                                            <Grid item xs={6}>
                                                <FormHelperText id="outlined-weight-helper-text">
                                                    <Typography
                                                        variant="body4"
                                                        color="customColor.label"
                                                        sx={{ ml: 0 }}
                                                    >
                                                        {i18n.events.postNewEvent.ticketPrice}
                                                        <span style={{ color: "red" }}>*</span>
                                                        <Select
                                                            value={ticketPriceType}
                                                            onChange={handleChange}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                            sx={{
                                                                backgroundColor: "#EEEEEE",
                                                                borderRadius: "24px",
                                                                height: "40px",
                                                                width: "80px",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    borderColor: "white !important",
                                                                },
                                                                "& .MuiOutlinedInput-notchedOutline:focus-visible":
                                                                {
                                                                    borderColor: "white",
                                                                },
                                                                "& .MuiOutlinedInput-notchedOutline:hover": {
                                                                    borderColor: "white",
                                                                },
                                                                "& .MuiOutlinedInput-root:hover": {
                                                                    borderColor: "white",
                                                                },
                                                                "& .MuiSelect-outlined:hover": {
                                                                    borderCollapse: "white !important",
                                                                },
                                                                "& .MuiSelect-root:hover": {
                                                                    borderCollapse: "white !important",
                                                                },
                                                                "& .MuiSelect-select:hover": {
                                                                    borderCollapse: "white !important",
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="free">Free</MenuItem>
                                                            <MenuItem value="paid">Paid</MenuItem>
                                                        </Select>
                                                    </Typography>
                                                </FormHelperText>
                                                <FormControl sx={{ mr: 1, minWidth: 120, mt: 1 }}>
                                                    <OutlinedInput
                                                        type="string"
                                                        size="small"
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        }
                                                        fullWidth
                                                        value={
                                                            ticketPriceType === "free" ? "Free" : ticketPrice
                                                        }
                                                        onChange={(event) => {
                                                            const inputValue = parseFloat(event.target.value);
                                                            if (!isNaN(inputValue) && inputValue > 0) {
                                                                setTicketPrice(inputValue);
                                                            } else {
                                                                setTicketPrice("");
                                                            }
                                                        }}
                                                        required
                                                        sx={{
                                                            background: "#F8F8F8",
                                                            color: "#000000",
                                                        }}
                                                        disabled={ticketPriceType === "free"}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={1} /> */}
                                            <Grid item xs={6} sx={{ mt: "8px" }}>
                                                <FormHelperText id="outlined-weight-helper-text">
                                                    <Typography
                                                        variant="body4"
                                                        color="customColor.label"
                                                        sx={{ ml: 0 }}
                                                    >
                                                        Genres
                                                        <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                </FormHelperText>
                                                <Autocomplete
                                                    multiple
                                                    id="Genres"
                                                    className="social-textField"
                                                    classes={classes}
                                                    value={genresValue}
                                                    onChange={(event, newValue) => {
                                                        const sortedValues = newValue
                                                            .slice()
                                                            .sort((a, b) => a.title.localeCompare(b.title));
                                                        setGenresValue(sortedValues as any);
                                                    }}
                                                    options={genres}
                                                    getOptionLabel={(option) => option.title}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.title === value.title
                                                    }
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Genres"
                                                            required
                                                        />
                                                    )}
                                                    sx={{ mt: "13px" }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <FormHelperText id="outlined-weight-helper-text">
                                <Typography
                                    variant="body4"
                                    color="customColor.label"
                                    sx={{ ml: 0 }}
                                >
                                    {i18n.events.postNewEvent.eventDetails}
                                </Typography>
                            </FormHelperText>
                            <TextField
                                sx={{
                                    input: {
                                        color: "customColor.black",
                                        fontSize: theme.typography.subtitle1.fontSize,
                                        fontWeight: theme.typography.subtitle1.fontWeight,
                                    },
                                    backgroundColor: "customColor.gray1",
                                }}
                                multiline
                                rows={3}
                                fullWidth
                                variant="outlined"
                                value={eventDetails}
                                onChange={handleDetailsChange}
                            ></TextField>
                            {validationMessageDetails && (
                                <Typography
                                    variant="caption"
                                    color="error"
                                    sx={{ textAlign: "right" }}
                                >
                                    {validationMessageDetails}
                                </Typography>
                            )}

                            <Typography
                                variant="body4"
                                color="customColor.label"
                                sx={{ marginLeft: "0px" }}
                            >
                                {i18n.events.postNewEvent.eventAddress}
                            </Typography>

                            <Typography
                                variant="body4"
                                color="customColor.label"
                                sx={{ marginLeft: "0px", mt: "9px" }}
                            >
                                Venue name
                            </Typography>

                            <Grid>
                                <TextField
                                    sx={{
                                        input: {
                                            color: "customColor.black",
                                            fontSize: "1rem",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                        },
                                        backgroundColor: "customColor.gray1",
                                        mt: "9px",
                                        mb: "10px",
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={venuStreetLocality}
                                    onChange={handleVenueChange}
                                ></TextField>
                                {validationMessageVenue && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ float: "right" }}
                                    >
                                        {validationMessageVenue}
                                    </Typography>
                                )}
                            </Grid>

                            <Typography
                                variant="body4"
                                color="customColor.label"
                                sx={{ marginLeft: "0px", mt: "9px" }}
                            >
                                Address
                            </Typography>

                            <Grid>
                                <TextField
                                    sx={{
                                        input: {
                                            color: "customColor.black",
                                            fontSize: "1rem",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                        },
                                        backgroundColor: "customColor.gray1",
                                        mt: "9px",
                                        mb: "10px",
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    value={address}
                                    onChange={handleAddressChange}
                                ></TextField>
                                {validationMessageAddress && (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        sx={{ float: "right" }}
                                    >
                                        {validationMessageAddress}
                                    </Typography>
                                )}
                            </Grid>

                            <FormHelperText
                                id="outlined-weight-helper-text"
                                sx={{ marginLeft: "0px  !important" }}
                            >
                                City, Country
                                <span style={{ color: "red" }}>*</span>
                            </FormHelperText>
                            <Grid
                                item={true}
                                xs={12}
                                sx={{
                                    input: {
                                        color: "customColor.black",
                                        fontSize: "1rem",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                    },
                                    backgroundColor: "customColor.gray1",
                                    // mt: "9px",
                                    mb: "10px",
                                }}
                            >
                                <GoogleLocationService
                                    defaultValue={cityCountry}
                                    onLocationChange={(locationDesc: string) =>
                                        setCityCountry(locationDesc)
                                    }
                                />
                            </Grid>

                            <FormHelperText id="outlined-weight-helper-text">
                                <Typography
                                    variant="body4"
                                    color="customColor.label"
                                    sx={{ ml: 0 }}
                                >
                                    {i18n.events.postNewEvent.eventTicketLink}
                                </Typography>
                            </FormHelperText>
                            <TextField
                                sx={{ mt: "9px", mb: "20px" }}
                                inputProps={{
                                    style: {
                                        background: "#F8F8F8",
                                        fontStyle: "normal",
                                        fontSize: "1rem",
                                        lineHeight: "20px",
                                        fontWeight: 500,
                                        color: "#000000",
                                    },
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={eventTicketLink}
                                onChange={handleTicketLinkChange}
                                placeholder={
                                    props.eventType === "modifypost" &&
                                        (!eventTicketLink || eventTicketLink.trim() === "")
                                        ? "Not mentioned"
                                        : ""
                                }
                            />
                            {validationTicketUrl && (
                                <Typography
                                    variant="caption"
                                    color="error"
                                    sx={{ textAlign: "right" }}
                                >
                                    {validationTicketUrlMessage}
                                </Typography>
                            )}
                            {!showAdvanceView ? (
                                <Grid container columnSpacing={1}>
                                    <Grid item={true} xs={3}>
                                        <FormHelperText sx={{ ml: 0 }}>
                                            <Typography
                                                variant="body4"
                                                color="customColor.label"
                                                sx={{ ml: 0 }}
                                            >
                                                {i18n.events.postNewEvent.startDate}
                                                <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        </FormHelperText>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                openTo="day"
                                                views={["year", "month", "day"]}
                                                inputFormat="MM/dd/yyyy"
                                                // minDate={minDate}
                                                value={startDate}
                                                onChange={(newValue: Date | null) => {
                                                    if (checkIsValidDate(newValue)) {
                                                        setStartDate(newValue);
                                                    }
                                                }}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            "&.MuiTextField-root": {
                                                                backgroundColor: "customColor.gray1",
                                                                width: "100%",
                                                            },
                                                            input: {
                                                                color: "customColor.black",
                                                                fontSize: theme.typography.subtitle1.fontSize,
                                                                fontWeight:
                                                                    theme.typography.subtitle1.fontWeight,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item={true} xs={3}>
                                        <FormHelperText sx={{ ml: 0 }}>
                                            <Typography
                                                variant="body4"
                                                color="customColor.label"
                                                sx={{ ml: 0 }}
                                            >
                                                {i18n.events.postNewEvent.startTime}
                                                <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        </FormHelperText>
                                        <LocalizationProvider
                                            sx={{ width: "100%" }}
                                            dateAdapter={AdapterDateFns}
                                        >
                                            {/* <StartTimePicker
                                    startDate={startDate}
                                    setStartDate={(newValue: Date | null) => {
                                        if (checkIsValidDate(newValue)) {
                                            setStartDate(newValue);
                                        }
                                    }}
                                    getMinStartTime={getMinStartTime}
                                /> */}
                                            <TimePicker
                                                value={startDate}
                                                onChange={(newValue: Date | null) => {
                                                    if (checkIsValidDate(newValue)) {
                                                        setStartDate(newValue);
                                                    }
                                                }}
                                                minTime={getMinStartTime()}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            input: {
                                                                color: "customColor.black",
                                                                fontSize: theme.typography.subtitle1.fontSize,
                                                                fontWeight:
                                                                    theme.typography.subtitle1.fontWeight,
                                                            },
                                                            "&.MuiTextField-root": {
                                                                width: "100%",
                                                            },
                                                        }}
                                                        variant={"outlined"}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item={true} xs={3}>
                                        <FormHelperText sx={{ ml: 0 }}>
                                            <Typography
                                                variant="body4"
                                                color="customColor.label"
                                                sx={{ ml: 0 }}
                                            >
                                                {i18n.events.postNewEvent.duration}
                                                <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        </FormHelperText>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            value={duration}
                                            onChange={(event: any) => setDuration(event.target.value)}
                                            sx={{
                                                backgroundColor: "customColor.gray1",
                                                color: "customColor.black",
                                                width: "100%",
                                            }}
                                        >
                                            {durationList.map((duration) => (
                                                <MenuItem key={duration.value} value={duration.value}>
                                                    {duration.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item={true} xs={3}>
                                        <FormHelperText sx={{ ml: 0 }}>
                                            <Typography
                                                variant="body4"
                                                color="customColor.label"
                                                sx={{ ml: 0 }}
                                            >
                                                {i18n.events.postNewEvent.timeZone}
                                                <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        </FormHelperText>
                                        <Autocomplete
                                            options={Constants.timezoneList}
                                            value={timezone}
                                            defaultValue={Constants.timezoneList[0]}
                                            onChange={(event: any, newValue: any) => {
                                                setTimezone(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        input: {
                                                            color: "customColor.black",
                                                            fontSize: theme.typography.subtitle1.fontSize,
                                                            fontWeight: theme.typography.subtitle1.fontWeight,
                                                        },
                                                        "&.MuiTextField-root": {
                                                            backgroundColor: "customColor.gray1",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Grid container columnSpacing={1}>
                                        <Grid item={true} xs={4}>
                                            <FormHelperText sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="body4"
                                                    color="customColor.label"
                                                    sx={{ ml: 0 }}
                                                >
                                                    {i18n.events.postNewEvent.startDate}
                                                </Typography>
                                            </FormHelperText>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    openTo="day"
                                                    views={["year", "month", "day"]}
                                                    inputFormat="MM/dd/yyyy"
                                                    // minDate={minDate as Date}
                                                    value={
                                                        props.eventType === "modifypost"
                                                            ? new Date(props.eventDetails.startDateTime)
                                                            : startDate
                                                    }
                                                    onChange={(newValue: Date | null) => {
                                                        if (checkIsValidDate(newValue)) {
                                                            setStartDate(newValue);
                                                        }
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                input: {
                                                                    color: "customColor.black",
                                                                    fontSize: theme.typography.subtitle1.fontSize,
                                                                    fontWeight:
                                                                        theme.typography.subtitle1.fontWeight,
                                                                },
                                                                "&.MuiTextField-root": {
                                                                    backgroundColor: "customColor.gray1",
                                                                    width: "100%",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item={true} xs={4}>
                                            <FormHelperText sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="body4"
                                                    color="customColor.label"
                                                    sx={{ ml: 0 }}
                                                >
                                                    {i18n.events.postNewEvent.startTime}
                                                </Typography>
                                            </FormHelperText>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StartTimePicker
                                                    startDate={startDate}
                                                    setStartDate={(newValue: Date | null) => {
                                                        if (checkIsValidDate(newValue)) {
                                                            setStartDate(newValue);
                                                        }
                                                    }}
                                                    getMinStartTime={getMinStartTime}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item={true} xs={4}>
                                            <FormHelperText sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="body4"
                                                    color="customColor.label"
                                                    sx={{ ml: 0 }}
                                                >
                                                    {i18n.events.postNewEvent.timeZone}
                                                </Typography>
                                            </FormHelperText>
                                            <Autocomplete
                                                options={Constants.timezoneList}
                                                value={timezone}
                                                defaultValue={Constants.timezoneList[0]}
                                                onChange={(event: any, newValue: any) => {
                                                    setTimezone(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            input: {
                                                                color: "customColor.black",
                                                                fontSize: theme.typography.subtitle1.fontSize,
                                                                fontWeight:
                                                                    theme.typography.subtitle1.fontWeight,
                                                            },
                                                            "&.MuiTextField-root": {
                                                                backgroundColor: "customColor.gray1",
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container columnSpacing={1}>
                                        <Grid item={true} xs={4}>
                                            <FormHelperText sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="body4"
                                                    color="customColor.label"
                                                    sx={{ ml: 0 }}
                                                >
                                                    {i18n.events.postNewEvent.endDate}
                                                </Typography>
                                            </FormHelperText>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    openTo="day"
                                                    views={["year", "month", "day"]}
                                                    inputFormat="MM/dd/yyyy"
                                                    maxDate={getMaxDate()}
                                                    minDate={getMinDate()}
                                                    value={endDate}
                                                    onChange={(newValue: Date | null) => {
                                                        if (checkIsValidDate(newValue)) {
                                                            setEndDate(newValue);
                                                        }
                                                    }}
                                                    onError={(reason: any, value: any) => {
                                                        if (reason) {
                                                            setErrorDate(true);
                                                        } else {
                                                            setErrorDate(false);
                                                        }
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                input: {
                                                                    color: "customColor.black",
                                                                    fontSize: theme.typography.subtitle1.fontSize,
                                                                    fontWeight:
                                                                        theme.typography.subtitle1.fontWeight,
                                                                },
                                                                "&.MuiTextField-root": {
                                                                    backgroundColor: "customColor.gray1",
                                                                    width: "100%",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <FormHelperText sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="body4"
                                                    color="customColor.label"
                                                    sx={{ ml: 0 }}
                                                >
                                                    {i18n.events.postNewEvent.endTime}
                                                </Typography>
                                            </FormHelperText>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePickerCmp
                                                    maxTime={maxTime}
                                                    minTime={minTime}
                                                    endDate={endDate}
                                                    setEndDate={(newValue: Date | null) => {
                                                        if (checkIsValidDate(newValue)) {
                                                            setEndDate(newValue);
                                                        }
                                                    }}
                                                    setErrorDate={setErrorDate}
                                                    errorDate={errorDate}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        {showAdvanceView ? (
                                            ""
                                        ) : (
                                            <Grid item={true} xs={4}>
                                                <FormHelperText sx={{ ml: 0 }}>
                                                    <Typography
                                                        variant="body4"
                                                        color="customColor.label"
                                                        sx={{ ml: 0 }}
                                                    >
                                                        {i18n.events.postNewEvent.timeZone}
                                                    </Typography>
                                                </FormHelperText>
                                                <Autocomplete
                                                    options={Constants.timezoneList}
                                                    value={timezone}
                                                    defaultValue={Constants.timezoneList[0]}
                                                    onChange={(event: any, newValue: any) => {
                                                        setTimezone(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                input: {
                                                                    color: "customColor.black",
                                                                    fontSize: theme.typography.subtitle1.fontSize,
                                                                    fontWeight:
                                                                        theme.typography.subtitle1.fontWeight,
                                                                },
                                                                "&.MuiTextField-root": {
                                                                    backgroundColor: "customColor.gray1",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Grid
                        container
                        columnSpacing={1}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Grid item={true} xs={12}>
                            <Typography
                                sx={{
                                    marginTop: "15px",
                                    color: "customColor.gray5",
                                }}
                                variant="subtitle1"
                            >
                                {endDate !== null
                                    ? `Ends at ${lightFormat(endDate, "MM/dd/yyyy - hh:mm aaa")}`
                                    : ""}

                                {/* {startDate?.setMinutes(startDate?.getMinutes() + duration)} */}
                            </Typography>

                            <IconButton
                                aria-label="close dialogbox"
                                // onClick={() => setShowAdvanceView(!showAdvanceView)}
                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                    event.preventDefault()
                                }
                                edge="end"
                                sx={{ marginTop: "5px" }}
                            >
                                <Typography
                                    sx={{ color: "customColor.link" }}
                                    variant="subtitle1"
                                >
                                    {/* {showAdvanceView ? "Advance" : "Advance"} */}
                                </Typography>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        columnSpacing={1}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Grid item={true}>
                            <Button
                                sx={{
                                    color: "#ff702A  !important",
                                    borderRadius: "24px !important",

                                    "&:hover": {
                                        borderColor: "#FF702A !important",
                                    },
                                    width: "140px",
                                    height: "40px",
                                    border: "2px solid #FF702A !important",
                                }}
                                size="large"
                                variant="outlined"
                                onClick={(e) => handleClose(e, "")}
                            >
                                {i18n.dialog.cancel}
                            </Button>
                        </Grid>
                        <Grid item={true}>
                            <Button
                                sx={{
                                    borderRadius: "24px",
                                    backgroundColor: "customColor.orange",
                                    color: "secondary",
                                    "&:hover": {
                                        backgroundColor: "customColor.orange",
                                        color: "secondary",
                                    },

                                    height: "40px",
                                }}
                                size="large"
                                endIcon={<img src={RightAarrow} alt="Right Arrow" />}
                                variant="contained"
                                onClick={() => createNewEvent()}
                                disabled={isDisabled()}
                                className="submit-button"
                            >
                                <Typography>
                                    {isLoading
                                        ? "Saving..."
                                        : 'Next'}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PostEvent;

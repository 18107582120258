import React, { useEffect, useState } from 'react'
import { Box, IconButton, Link, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useUser } from '../context/UserContext';
const PromotionalBanner = ()=> {   
    const user = useUser();
    const [showBanner, setShowBanner] = useState<boolean>(
        localStorage.getItem("showBanner") !== "false"
    );

    useEffect(() => {
        if (user?.deviceId) {
            localStorage.setItem("showBanner", "false");
            setShowBanner(false);
        } else if (localStorage.getItem("showBanner") === null) {
            localStorage.setItem("showBanner", "true");
            setShowBanner(true);
        }
    }, [user?.deviceId]);

    function handleBannerVisibility(){
        setShowBanner(false);
        localStorage.setItem("showBanner","false");
    }
    
    if(!showBanner) return null;
    return (
           <Box
               sx={{
                   height: "45px",
                   backgroundImage: "linear-gradient(to bottom, #f2a661, #f77b60)", // Light to dark orange from top to bottom
                   color: "white",
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "center",
                   "&:hover #banner-close-btn": { visibility: "visible" }
               }}
           >
               <Typography sx={{ color: "white", flexGrow: "1", textAlign: "center", fontSize: "medium" }}>
                   Order Lutefish Stream device{" "}
                   <Link
                       href="https://lutefish.com/products/lutefish"
                       target="_blank"
                       sx={{
                           textDecoration: "underline !important",
                           color: "white",
                       }}
                   >
                       here.
                   </Link>
               </Typography>
               <IconButton id="banner-close-btn" onClick={handleBannerVisibility} sx={{ color: "white", alignItems: "flex-end", right: "5vw", visibility:"hidden", position:"absolute" }}>
                   <HighlightOffIcon sx={{ height: "35px", width: "30px" }} />
               </IconButton>
           </Box>
       ) 
}

export default PromotionalBanner

import { Button, Card, CardMedia, Chip, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay"
import Group from "../../images/Group.svg";
import calendarIcon from "../../images/calendar.svg";
import Location from "../../images/location.svg";
import Venue from "./Venue";
import { useEffect, useState } from "react";
import ExportIcon from "../../images/export.svg";
import { EventsSidePanel } from "../commonComponents/EventsSidePanel";

const ViewGoogleEvent = () => {
    const urlLocation = useLocation()
    const [alertStatus, setAlertStatus] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
	const [location, setLocation] = useState({ lat: 0, lng: 0 });

    const googleEventDetails = urlLocation.state?.googleEventDetails;
    const handleAlertClose = () => {
        setShowAlert(false);
    };
    const concatenatedString = `${googleEventDetails.address[0]} ${googleEventDetails.address[1]}`;

   
    function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

    useEffect(() => {
        if (concatenatedString && concatenatedString.trim() !== "") {
        getLocationLatLng(concatenatedString).then((result) => {
            setLocation({ lat: result.lat, lng: result.lng });
        });
    }
        const backButton = document.getElementById("backButton");    
        // Set autofocus
        if (backButton) {       
            backButton.focus();
        }

	}, []);


    return (
        <>
            <Grid className="center-align mainPanel" sx={{ margin: "auto" }}>
                {/* <ErrorAlertDisplay
                    alertMsg={alertMsg}
                    alertStatus={alertStatus}
                    showAlert={showAlert}
                    handleAlertClose={handleAlertClose}
                /> */}
                {/* {loading ? (
                <Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
                    <CircularProgress />
                    <Grid container item justifyContent="center">
                        Loading ...
                    </Grid>
                </Grid>
            ) : ( */}
                <Grid
                    container={true}
                    className="column mainPanel"
                    rowSpacing={{ xs: 1 }}
                >
                    <Grid
                        item={true}
                        className="mainPanel"
                        sx={{ zIndex: 99, width: "100%" }}
                    >
                        <Grid item={true} xs={12} mt={3}>
                            <Grid container={true} columns={{ xs: 12 }}>
                                <Grid item xs={6}>
                                    <Button
                                    id="backButton"
                                        variant="text"
                                        startIcon={
                                            <img src={Group} alt="Back to previous page" />
                                        }
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "36px !important",
                                                lineHeight: "normal",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                maxWidth: "50rem",
                                            }}
                                        >
                                            {googleEventDetails.title}
                                        </Typography>
                                    </Button>                                   
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* </Grid> */}
                        <Grid item={true} xs={12} mt={3}>
                            <Grid container={true} columns={{ xs: 12 }}>
                                <Grid item xs={9} className="centerPanelwoRightEvent">
                                    <Card  sx={{height:"228px", textAlign:"center", backgroundColor:"#d3d3d3"}}>
                                        <img src={googleEventDetails.image} alt="google" style={{height:"228px"}} />
                                    </Card>
                                    {/* Event Details */}
                                    <Card
                                        sx={{
                                            // width: "912px",
                                            // height: "270px",
                                            borderRadius: "12px",
                                            marginTop: "30px",
                                            padding: "16px 22px 30px 62px",
                                        }}
                                    >

                                        <Grid item>
                                            <IconButton color="primary" size="small">
                                                <img
                                                    src={calendarIcon}
                                                    alt="Date"
                                                    style={{ height: "24px", width: "24px" }}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "18px !important",
                                                    lineHeight: "22.5px",
                                                    display: "contents",
                                                }}
                                            >
                                                {googleEventDetails.date.when}
                                            </Typography>
                                        </Grid>

                                        <Grid container item xs={12} >
                                            <Grid item xs={0.5}>
                                            <IconButton color="primary" size="small">
                                                <img
                                                    src={Location}
                                                    alt="Location"
                                                    style={{ height: "24px", width: "24px" }}
                                                />
                                            </IconButton>
                                            </Grid>
                                            <Grid item xs={11.5} sx={{pt:"5px"}}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "14px !important",
                                                    display: "contents",
                                                }}
                                            >
                                                {concatenatedString}
                                            </Typography>
                                            </Grid>
                                            
                                            
                                        </Grid>
                                        <Grid item sx={{ marginTop: "10px", paddingLeft: "5px" }}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "24px !important",
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                Event Details
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "16px !important",
                                                    lineHeight: "20px",
                                                    color: "#5B5B65",
                                                }}
                                            >
                                                {googleEventDetails.description}
                                            </Typography>
                                        </Grid>

                                    </Card>

                                    <Card
                                        sx={{
                                            // width: "912px",
                                            // height: "463px",
                                            borderRadius: "12px",
                                            marginTop: "30px",
                                            padding: "16px 22px 30px 62px",
                                        }}
                                    >
                                        <Grid
                                            container={true}
                                            columns={{ xs: 12 }}
                                            sx={{ padding: "6px" }}
                                        >
                                            <Grid container={true} xs={6}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Lexend",
                                                        fontStyle: "normal",
                                                        fontWeight: "500",
                                                        fontSize: "24px !important",
                                                        lineHeight: "30px",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    Location
                                                </Typography>

                                                <Card sx={{ width: "402px", height: "357px" }}>
														<Venue
															lat={location.lat}
															lng={location.lng}
															location={location}
														/>
													</Card>

                                                {/* <Card sx={{ width: "402px", height: "357px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        window.open(googleEventDetails.event_location_map.link, "_blank");
                                                    }
                                                    }
                                                >
                                                    <CardMedia
                                                        component="img"
                                                        image={googleEventDetails.event_location_map.image}

                                                        sx={{
                                                            borderRadius: "12px",
                                                            objectFit: "fill"
                                                        }}
                                                    />
                                                </Card> */}
                                            </Grid>
                                            <Grid container={true} xs={6}>

                                                <Grid
                                                    item={true}
                                                    xs={12}
                                                    sx={{ marginLeft: { md: "4rem", lg: "7rem" } }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            borderRadius: "24px",
                                                            color: "secondary",

                                                            justifyContent: "center",
                                                            alignItems: "flex-start",
                                                            textAlign: "left",
                                                            fontFamily: "Lexend",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            fontSize: "24px !important",
                                                            lineHeight: "30px",
                                                        }}
                                                    >
                                                        Buy Ticket
                                                    </Typography>                                             
                                                            
                                                    <Button
                                                       
                                                        sx={{
                                                            borderRadius: "24px",
                                                            // backgroundColor: "customColor.orange",
                                                            color: "secondary",
                                                            "&:hover": {
                                                                backgroundColor: "customColor.orange",
                                                                color: "secondary",
                                                            },
                                                            // background:
                                                            // 	eventDetail.ticketLink !== null &&
                                                            // 	eventDetail.ticketLink !== "null" && user.userAccountStatus !== "SUSPEND"
                                                            // 		? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)"
                                                            // 		: "#C2C2C3",
                                                            width: "239px",
                                                            height: " 48px",
                                                            padding: "12px 12px 12px 64px",
                                                            alignItems: "flex-start",
                                                            textAlign: "left",
                                                            fontFamily: "Lexend",
                                                            fontSize: "1.125rem",
                                                            fontStyle: "normal",
                                                            fontWeight: 500,
                                                            lineHeight: "normal",
                                                            letterSpacing: "0.9px",
                                                            justifyContent: "center",
                                                            mt: "30px",
                                                        }}
                                                        size="large"
                                                        endIcon={
                                                            <img src={ExportIcon} alt="Export" />
                                                        }
                                                        variant="contained"
                                                        // disabled={
                                                        // 	eventDetail.ticketLink !== null &&
                                                        // 	eventDetail.ticketLink !== "null" &&
                                                        // 	user.userAccountStatus !==
                                                        // 		  "SUSPEND"
                                                        // 		? false
                                                        // 		: true
                                                        // }
                                                        className="submit-button"
                                                        onClick={() => {
                                                            window.open(googleEventDetails.link, "_blank");
                                                        }
                                                        }
                                                    >
                                                        <Typography>Buy Ticket</Typography>
                                                    </Button>
                                                                   
                                                       
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={2.6} className="rightPanelWidthEvent">
                                    <Card
                                        sx={{
                                            width: "240px",
                                            minHeight: "200px",
                                            padding: "10px 24px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: "24px",
                                                fontWeight: "500",
                                                color: "#000",
                                            }}
                                        >
                                            Similar Events
                                        </Typography>
                                        <EventsSidePanel type={"EVENT"} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* )} */}
            </Grid>




        </>
    )
}

export default ViewGoogleEvent
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Grid
} from "@mui/material";
import AddDeviceDialog from "../dialogBox/AddDeviceDialog";
import HelpAndTips from "./HelpAndTips";
import Danger from "../images/danger.svg";
import RightAarrow from "../images/right arrow.svg";
import i18n from "../l10n/strings.json";
import { DeviceHistoryContainer } from "../components/DeviceHistoryContainer";
import { AdminDeviceHistoryContainer } from "../components/AdminDeviceHistoryContainer";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { useUser } from "../context/UserContext";
import { Row } from "jspdf-autotable";

const AddAdminDeviceSetting = () => {
	const navigate = useNavigate();
	const param = useParams();
	const user = useUser()
	const [open, setOpen] = useState(false);
	const [deviceHistory, setDeviceHistory] = useState<any>([]);
	const token = localStorage.getItem("sessionId");
	const location = useLocation()
	const { adminSetUserId } = useAdminContext();
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
	);
	const { userId, userProfileId, email, user: userDetails, userName, userStatus,userObject } = location?.state || {}

	const handleSuccess = () => {
		navigate("/home/admin/settings/deviceSetup/admindevice", {
			state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject },
		});
	};

	const handleDialogClose = () => {
		setOpen(false);
	};
	const isAdminUser = () => {
		if (user?.authorities[0].name == 'ROLE_USER' || user?.authorities[0].name == 'ROLE_COLLAB_USER') {
			return false;
		} else {
			return true;
		}
	};
	const isEnable = () => {
		if (user?.authorities[0].name === "ROLE_ADMIN" || user?.authorities[0].name == 'ROLE_SUPER_ADMIN'
			|| user?.authorities[0].name == 'ROLE_USER' || user?.authorities[0].name == 'ROLE_COLLAB_USER') {
			return true;
		} else {
			return false;
		}
	};
	useEffect(() => {
		getDeviceHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCompatibilityTest = () => {
		//setShowCompatibilityPopup(true);
		window.open("https://speedtest.lutefish.com/", "_blank");
	};

	const getDeviceHistory = () => {

		const validId = location.state?.userId ? location.state?.userId : user.id
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-devices/get-devices-by-user`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify([
					{
						userId: validId
					}
				]),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setDeviceHistory(data);
				}
			});
	};
	return (
		<>
			<Grid container direction="row" sx={{marginTop:'-46px'}}>
			<Grid xs={6} >
					 
						<Typography sx={{fontSize:"24px"}}>Device</Typography>
					 
				</Grid>
				<Grid xs={3} sx={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}>
				<Button
											//className="cancel-button button-field-custom-font-size"
											size="large"
											variant="outlined"
											onClick={handleCompatibilityTest}
											disabled={!isEnable()}
											sx={{
												fontSize: '0.9rem', justifySelf: "right",
												lineHeight: '20px',
												border: '2px solid #0273E6',
												color: '#0273E6',
												ml: "15px",
												transition: 'border 0.1s',

												"&:hover": {
													border: "2px solid #0273E6",
													backgroundColor: "#E9F1FF",
												},
											}}
										>
											{i18n.settings.compatibility.dialogTitle}
										</Button>
				</Grid>
				<Grid xs={3} sx={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}>
					<Button
						className="submit-button"
						size="large"
						sx={{
							minWidth: "200px",
						}}
						endIcon={<img src={RightAarrow} alt="Right Arrow" />}
						variant="contained"
						disabled={!isEnable()}
						onClick={() => setOpen(true)}
					>
						<Typography>{i18n.settings.deviceSetup.add}</Typography>
					</Button>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item={true} xs={9} className="centerPanelwoRight">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
							// height: "376px",
						}}
					>
						<CardContent>
							<Grid container={true} xs={12}>
								<Grid item={true} xs={12} color="#000000">
									<Grid
										container={true}
										xs={12}
										sx={{
											display: "flex",
											alignItems: "center",
											flexWrap: "wrap",
										}}
									>
										<Grid item={true} xs={1}>
											<img src={Danger} alt="Preferences" />
										</Grid>
										<Grid item={true} xs={11}>
											<Typography
												//className="card-title-custom-size"
												style={{ color: "red" }}
											>
												No Device added or purchase for this account
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								{/* <Grid item={true} xs={12}>
									<Typography
										className="typography-field-custom-font-size"
										sx={{
											lineHeight: "25px",
											padding: "30px 0px 30px 0px",
										}}
									>
											 
										Use add device button to add your new device  
										
									</Typography>
								</Grid> */}
							</Grid>
						</CardContent>
						<CardActions
							sx={{
								justifyContent: "flex-end",
								marginRight: "32px",
							}}
						>
							{/* <Grid>
								<Button
									className="submit-button"
									size="large"
									sx={{
										minWidth: "200px",
									}}
									endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									variant="contained"
									disabled={!isEnable()}
									onClick={() => setOpen(true)}
								>
									<Typography>{i18n.settings.deviceSetup.add}</Typography>
								</Button>
							</Grid> */}
						</CardActions>
					</Card>

					<Grid container xs={12} rowSpacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
								History
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Card
								sx={{
									background: "#FFFFFF",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "border-radius: 12px;",
									padding: 2,
								}}
							>
								{deviceHistory.length === 0 && (
									<Grid
										container
										xs={12}
										sx={{ display: "flex", textAlign: "center" }}
									>
										<Grid item xs={12}>
											<Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
												No Device History Found
											</Typography>
										</Grid>
									</Grid>
								)}
								{deviceHistory
									.filter((item: any) => item.status === "Inactive")
									.map((item: any) => (
										<div key={item.id}>
											<AdminDeviceHistoryContainer device={item} />

										</div>
									))}
							</Card>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item={true} xs={3}  className="rightPanelWidth">
					<HelpAndTips
						title={
							i18n.settings.deviceSetup.instructions.howToFindSerialNo.title
						}
						text={i18n.settings.deviceSetup.instructions.howToFindSerialNo.text}
					/>
				</Grid> */}
			</Grid>

			<AddDeviceDialog
				open={open}
				onSuccess={handleSuccess}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default AddAdminDeviceSetting;

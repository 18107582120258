export interface MetronomeSoundEffectDTO {
	id: number;
	soundEffectName: string;
	soundEffectFileName: string;
}

export const getAllSoundEffects = async (
	token: string
): Promise<MetronomeSoundEffectDTO[]> => {
	try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/metronome/sound-effects`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});

		if (!response.ok) {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}

		return await response.json();
	} catch (error) {
		console.error("Failed to fetch sound effects:", error);
		throw error;
	}
};

import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	Box,
	Typography,
	Grid,
	Autocomplete,
	TextField,
} from "@mui/material";
import GoogleLocationService from "../components/GoogleLocationService";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import useLocationAutocomplete from "../hooks/useLocationAutocomplete/useLocationAutocomplete";
import { Link, useNavigate } from "react-router-dom";

const EditLocation = (props: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");

	const [selectedLocation, setSelectedLocation] = useState(props.location);
	// const [searchString, setSearchString] = useState("");
	// const cities = useLocationAutocomplete(searchString);
	const [lat, setLat] = useState<Number>(0);
	const [longi, setLongi] = useState<Number>(0);
	const navigate = useNavigate();

	useEffect(() => {
		if (props.location !== selectedLocation) {
			setSelectedLocation(props.location);
		}
	}, [props.location]);

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {

		if (!location || location.trim() === "") {
			return Promise.reject(new Error("Location must not be empty or undefined"));
		}

		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					const result = { lat, lng };
					setLat(result.lat);
					setLongi(result.lng);
					return result;
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const saveLocation = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-location`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					user: {
						// id: user.id,
						id: props.userIdByAdmin ? props.userIdByAdmin : user.id,
					},
					location: selectedLocation,
					latitude: lat,
					longitude: longi,
				}),
			}
		)
			.then((response) => {

				if(response.status === 502){
						navigate('/defaultPage')
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				props.handleClose(true, "locationSuccess");
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		setSelectedLocation(props.location);
		props.handleClose(false);
	};
	useEffect(() => {
		if(selectedLocation && selectedLocation.trim() !== "") {
		getLocationLatLng(selectedLocation).then((result) => {
			setLat(result.lat);
			setLongi(result.lng);
		});
	}
	}, [selectedLocation]);

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
				// zIndex: "7 !important"
			}}
			fullWidth
			maxWidth="sm"
			open={props.locationOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.editLocationDialog}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid container>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								// fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.location}
						</FormHelperText>
					</Grid>
					<Grid item={true} xs={12} sx={{ marginBottom: "100px" }}>
						<GoogleLocationService
							defaultValue={selectedLocation}
							onLocationChange={(locationDesc: string) =>
								setSelectedLocation(locationDesc)
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "135px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>
					<Grid item={true}>
						<Button
							className="submit-button"
							size="large"
							variant="contained"
							sx={{ width: "135px" }}
							disabled={selectedLocation === ""}
							onClick={saveLocation}
						>
							{i18n.dialog.save}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default EditLocation;

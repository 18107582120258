import { useState, useEffect } from "react";
import { Outlet, useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
	Alert,
	Box,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Divider,
	Grid,
    Button,
    FormControl,
    FormHelperText,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    Slider
} from "@mui/material";
import { createStyles,  makeStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import AllMusicians from "./AllMusicians";
import MyConnections from "./MyConnections";
import Invitations from "./Invitations";
import i18n from "../../l10n/strings.json";
import InstrumentSkillLevel from "./InstrumentSkills";

import Guitar from "../../images/guitar.svg";
import Drums from "../../images/drums.svg";
import Violin from "../../images/Violin.svg";
import Trumpet from "../../images/trumpet.svg";
import MusicNote from "../../images/musicnote.svg";
import Account from "../../images/account.svg";
import ActiveAccount from "../../images/active-account.svg";
import Security from "../../images/security.svg";
import ActiveSecurity from "../../images/active-security.svg";
import Next from "../../images/next.svg";
import InvitationSent from "../../images/InvitationSent.svg"
import InvitationsSent from "./InvitationsSent";
import Mutuals from "./Mutuals";
import Group from "../../images/Group.svg";
import MusiciansFilter from "./MusiciansFilter";

import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import MusicianSearchResult from "./MusicianSearchResult";

type RouteParams = {
	menuId: string;
};

const invitationsMenu = [
    {
        id:"invitations-received",
        label: "Received",
        activeIcon: ActiveAccount,
        inActiveIcon: Account,
        disabled: false
    },
    {
        id:"invitations-sent",
        label: "Sent",
        activeIcon: InvitationSent,
        inActiveIcon: InvitationSent,
        disabled: false
    },
    {
        id:"invitations-blocked",
        label: "Blocked",
        activeIcon: ActiveSecurity,
        inActiveIcon: Security,
        disabled: true
    }
];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
            padding: "1% 2%", 
            borderRadius: "15px",
            //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
		activeMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
		},
        backBtn:{
            width: "100%",
            fontWeight: "500 !important", color: "#0273E6 !important"
        },
	})
);

type Genres = {
	id: string;
	label: string;
};

type Instrument = {
	id: string;
	label: string;
	icon: string;
};

const genreFilter:Array<Genres> = [
    {
        id: "jazz",
        label: "Jazz"
    },
    {
        id: "pop",
        label: "Pop"
    },
    {
        id: "classic",
        label: "Classic"
    },
    {
        id: "punk",
        label: "Punk"
    },
    {
        id: "metal",
        label: "Metal"
    },
];

const instrumentFilter:Array<Instrument> = [
    {
        id: "guitar",
        label: "Guitar",
        icon: Guitar
    },
    {
        id: "drums",
        label: "Drums",
        icon: Drums
    },
    {
        id: "violin",
        label: "Violin",
        icon: Violin
    },
    {
        id: "trumpet",
        label: "Trumpet",
        icon: Trumpet
    },
]

const MusiciansFilterContainer = () => {
	const param = useParams<RouteParams>();
    const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
    const {musicianFilter, isFilterActive} = useMusiciansFilter();
    const location = useLocation();
	const [pageTitle, setPageTitle] = useState("");
	const [genres, setGenres] = useState("");
    const [selectedInstrumentFilter, setSelectedInstrumentsFilter] = useState<Array<Instrument> | []>([]);

	useEffect(() => {
        let view = window.location.href.split("/").pop();
		switch (view) {
            case "mutuals":
                setPageTitle('Mutual Connections');
                break;
            case "recent":
                setPageTitle('Recently Joined');
                break;
            case "active":
                setPageTitle('Active Musicians');
                break;
            case "recomended":
                setPageTitle('Similar Musicians');
                break;
        
            default:
                break;
        }

        if(window.location.href.split("/").slice(0, -1).pop() === "mutuals"){
            setPageTitle("Mutual Connections")
        }
	}, []);
    
    const updateSelectedInstruments = (newInstrumentSet: any) => {
        setSelectedInstrumentsFilter(newInstrumentSet);
	};

    const updateInstrumentFilter = (instrument:string, skillLevel:number) => {
    }

	return (
		<>
			<Grid className="mainPanel"
				spacing={{ xs: 1 }}
			>
				<Grid
					container={true}
					className="column"
					sx={{
						width: "80%",
					}}
					// rowSpacing={{ xs: 1 }}
                    mt={2}
				>
					<Grid item={true}
						className="center-align"
						// style={{
						// 	padding: "10px",
						// }}
					>
						<SettingsAlert />
					</Grid>
					<Grid item={true} sx={{position: "relative", height: "10px", mb: 3}}>
						<Grid container={true}
							columns={{ xs: 12 }}
							columnSpacing={{ xs: 1 }}
						>
							<Grid item={true} xs={12} className="verticalAlign" >
                                {/* <img src={Group} alt="Back" /> Back */}
                                <Button variant="text" startIcon={<img src={Group} alt="Connect" />} onClick={() => { window.history.back(); }} >
                                    <span style={{color: "#0273E6"}}>Back</span>
                                </Button>
                            </Grid>
							<Grid item={true} xs={12} pl={3}>
								<Typography
									className="home-title-custom-size"
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										// fontSize: "36px",
										lineHeight: "49px",
										color: "#000000",
									}}>
                                    {pageTitle}
								</Typography>

							</Grid>
							{/* <Grid container={true} xs={9} gap={1}>
								{
                                    musiciansMenu.map((menu:any) => {
                                        return menu.id === activeMenu ?                                        
                                                <Button variant="contained" className={classes.activeMenu} onClick={() => changeMenu(menu.id)}
                                                >{menu.label}</Button>
                                            :
                                            <Button variant="text" className={classes.musicMenu} onClick={() => changeMenu(menu.id)}
                                            >{menu.label}</Button>
                                    })
                                }
							</Grid> */}
						</Grid>
					</Grid>
					<Grid item={true} className="row" mt={7}>
						<Grid container={true} xs={12} className="mainPanel" >
                            <Grid item xs={3} className="leftPanelWidth">
                                <MusiciansFilter />
                            </Grid>

							<Grid item container xs={9} mt={0.5} className="centerPanelwoRightGig"
                            >
                                {
                                    isFilterActive && !location.pathname.startsWith("/musicians/mutuals/") ?
                                        <MusicianSearchResult />
                                    :
                                        <Outlet />
                                }
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default MusiciansFilterContainer;

import EventEmitter from "events";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import { UserInterface } from "../types/types";
// import WebsocketClient from "./WebsocketClient";
// import { useUser } from "../context/UserContext";
import { activeMqttClientEnv } from "../configs";

/**
 * Fetches and stores the user info in memory.
 * Fires changed event in case of user is logged in or out.
 *
 * @event changed
 * @event loggedIn
 * @class User
 * @extends EventEmitter
 */

class WebsocketClientUserStatus extends EventEmitter {
	public websocketClientUserStatus: any = undefined;
	public liveStreamClient: any = undefined;
	public sessionStatus: string = "";
	public token = localStorage.getItem("sessionId");

	constructor() {
		super();
	}

	// public addLog(status: string) {
	// 	const dto = {
	// 		websocketLogs: status,
	// 		createdBy: null,
	// 		session: null,
	// 		userProfileId: null ,
	// 	};
	// 	fetch(`${process.env.REACT_APP_BASE_URL}/api/websocket-logs/save`, {
	// 		method: "POST",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			// Authorization: `Bearer ${localStorage.getItem("sessionId")}`,
	// 		},
	// 		body: JSON.stringify(dto),
	// 	})
	// 		.then((response) => {
	// 			if (response.status >= 400) {
	// 				return null;
	// 			} else {
	// 				return response.json();
	// 			}
	// 		})
	// 		.then((res) => {
	// 			console.log(res);
	// 		});
	// }

	public async createConnectionUSerStatus(username: string) {
		let Sock = new SockJS(`${process.env.REACT_APP_BASE_URL}/ws`);
		this.websocketClientUserStatus = over(Sock);
		this.websocketClientUserStatus.debug = null;
		this.websocketClientUserStatus.isConnected = false;
        // console.log("create connection status");
		this.websocketClientUserStatus.connect(
			{},
			() => {
				const subscribeTo = `/public/userStatus/${username}`;
				this.websocketClientUserStatus.isConnected = true;
				// console.log("in created connection condition");
				// this.addLog("Connected to WebSocket");
				//this.addLog(`Connected to WebSocket: Socket is connected : ${Boolean(this.websocketClientUserStatus.isConnected)}`);
				this.websocketClientUserStatus.subscribe(
					subscribeTo,
					this.onMessageReceive
				);

				let message = {
					action: "userOnlineStatus",
					status: "Online",
					// timestamp: new Date().toISOString(),
					loginBy: username,
				};

				this.websocketClientUserStatus.send(
					"/app/message",
					{},
					JSON.stringify(message)
				);
			},
			(error: any) => {
				console.error("Connection lost:", error);
				this.handleUserSignOut(username);
				this.onConnectionLost(username);
			}
		);
	}
	onConnectionLost = (username: any) => {
		// this.addLog("Disconnected");
		// this.createConnectionUSerStatus(username);
		// this.addLog("Reconnected");
		this.websocketClientUserStatus.isConnected = false;
		// console.log("on connection lost ");
		// this.addLog("Disconnected");
		// this.addLog(`Disconnected: Socket is connected : ${this.websocketClientUserStatus.isConnected}`);
		this.ReCreateConnectionUSerStatus(username);
	};

	public async ReCreateConnectionUSerStatus(username: string) {
		let Sock = new SockJS(`${process.env.REACT_APP_BASE_URL}/ws`);
		this.websocketClientUserStatus = over(Sock);
		this.websocketClientUserStatus.debug = null;
		//commenting as it making connection status false 
		this.websocketClientUserStatus.isConnected = false;
		// console.log("in recreate::");
		this.websocketClientUserStatus.connect(
			{},
			() => {
				const subscribeTo = `/public/userStatus/${username}`;
				this.websocketClientUserStatus.isConnected = true;
				// console.log("in recreate condition::");
				// this.addLog("ReConnected in ReCreateConnectionUSerStatus");
				// this.addLog(`ReConnected in ReCreateConnectionUSerStatus: Socket is connected : ${Boolean(this.websocketClientUserStatus.isConnected)}`);
				this.websocketClientUserStatus.subscribe(
					subscribeTo,
					this.onMessageReceive
				);

				let message = {
					action: "userOnlineStatus",
					status: "Online",
					// timestamp: new Date().toISOString(),
					loginBy: username,
				};

				this.websocketClientUserStatus.send(
					"/app/message",
					{},
					JSON.stringify(message)
				);
			},
			() => {
				console.error("Connection lost:");
				this.handleUserSignOut(username);
				this.onConnectionLost(username);
			}
		);
	}

	onLiveStreamConnectionLost = () => {
		this.liveStreamClient.isConnected = false;
		this.conntectToStreamTopic();
	};

	public async conntectToStreamTopic() {
		let Sock = new SockJS(`${process.env.REACT_APP_BASE_URL}/ws`);
		this.liveStreamClient = over(Sock);
		this.liveStreamClient.debug = null;
		this.liveStreamClient.isConnected = false;

		this.liveStreamClient.connect({}, () => {
			const subscribeTo = `/public/livestreamstatus`;
			this.liveStreamClient.isConnected = true;
			this.liveStreamClient.subscribe(
				subscribeTo,
				this.onLiveStreamMessageReceive
			);
		},
			() => {
				console.error("Connection lost:");
				this.onLiveStreamConnectionLost();

			}
		);
	}

	handleUserSignOut = (user: any) => {
		let message = {
			action: "userOfflineStatus",
			status: "Offline",
			// timestamp: new Date().toISOString(),
			loginBy: user,
		};

		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(message)
		);
		this.websocketClientUserStatus.unsubscribe(`/public/userStatus/${user}`);
		this.websocketClientUserStatus.disconnect();
	};

	onLiveStreamMessageReceive = (payload: any) => {
		let data: any;
		try {
			data = JSON.parse(payload.body);
		} catch (error) {
			console.log("Failed to parse Json", error);
			return;
		}

		if (data.action === "live_stream_status") {
			this.emit("liveStreaming", data);
		}
		else if(data.action === "live_stream_like"){
			this.emit("liveStreamLiked", data);
		}
	};

	onMessageReceive = (payload: any) => {
		let payloadData: any;
		try {
			payloadData = JSON.parse(payload.body);
		} catch (error) {
			// this.addLog("Failed to parse Json in catch");
			console.log("Failed to parse Json", error);
			return;
		}
		if (payloadData.action === "userOnlineStatus") {
			this.emit("userStatus", payloadData);
		} else if (payloadData.action === "userOfflineStatus") {
			this.emit("userStatus", payloadData);
		} else if (payloadData.action === "notification_count") {
			localStorage.setItem(
				"notification",
				JSON.stringify({
					url: `/public/userStatus/${payloadData.loginBy}`,
					email: payloadData.loginBy,
					count: payloadData.unreadNotificationCount,
					connected: true,
				})
			);
			this.emit("notification", payloadData);
		}
	};

	// Jam session

	startSession = (data: any, user: UserInterface) => {
		if (this.websocketClientUserStatus) {
			const subscribeTo = `/public/${data?.twilioSessionId}`;
			this.websocketClientUserStatus.subscribe(
				subscribeTo,
				this.onMessageReceived
			);

			const subscribeTo1 = `/public/deviceError/${data?.twilioSessionId}/${user?.nickname}`;
			console.log("web sub to-------->", subscribeTo1);
			this.websocketClientUserStatus.subscribe(
				subscribeTo1,
				this.onMessageReceived
			);

			let message = null;
			// const sessionStatus = localStorage.getItem("sessionStatus");
			if (this.sessionStatus !== "SESSION_STARTED") {
				message = {
					action: "start_session",
					timestamp: new Date().toISOString(),
					session_id: data?.twilioSessionId,
					created_by: user.id,
				};
				this.websocketClientUserStatus.send(
					"/app/message",
					{},
					JSON.stringify(message)
				);
			}
		} else {
			this.ReCreateConnectionUSerStatus(data.created_for);
			setTimeout(() => {
				this.startSession(data, user);
			}, 3000);
		}
	};

	public async updateSessionStatus(status: any | undefined) {
		if (status) {
			this.sessionStatus = status;
		}
	}

	onMessageReceived = (payload: any) => {
		let payloadData: any;
		try {
			payloadData = JSON.parse(payload.body);
				// console.log("response from device nickname-------->",payload.data)
				// if(payloadData.action === "start_session" ||
				// 	payloadData.action === "join_session"){
					// console.log("PayloadData-------->", payloadData)
					// console.log("PayloadData.allUsersAudioControls-------->", JSON.parse(payloadData?.allUsersAudioControls));
				// }
		} catch (error) {
			// this.addLog("Failed to parse Json in catch");
			console.log("Failed to parse Json", error);
			return;
		}
		if (
			payloadData.action === "start_session" ||
			payloadData.action === "join_session"
		) {
			this.emit("subcribed", payload);
		} else if (payloadData.action === "audio_status") {
			this.emit("audioControl", payloadData);
		} else if (payloadData.action === "device_latency") {
			this.emit("latency", payloadData);
		} else if (payloadData.action === "emoji") {
			this.emit("emojiChanged", payloadData);
		} else if (payloadData.action === "audio_recording") {
			this.emit("recordingControl", payloadData);
		} else if (payloadData.action === "session_stop") {
			// this.addLog("Session stop in WebsocketClientUserStatus");
			this.emit("leaveSession", payloadData);
		} else if (payloadData.action === "audio_effect") {
			this.emit("audioEffect", payloadData);
		} else if (payloadData.action === "ping_device") {
			this.emit("ping_device", payloadData);
			// this.websocketClientUserStatus.unsubscribe(
			// 	`/public/${payloadData.metadata.created_by}`
			// );
			// this.websocketClientUserStatus.disconnect();
		} else if (payloadData.action === "device_status") {
			this.emit("audioControl", payloadData);
		} else if (payloadData.action === "audio_details") {
			this.emit("audioDetails", payloadData);
		} else if (payloadData.action === "session_broadcasting") {
			this.emit("sessionBroadcasting", payloadData);
		} else if (payloadData.action === "error") {
			this.emit("errorCode", payloadData);
		}  else if (payloadData.action === "video_recording") {
			this.emit("videoRecording", payloadData);
		} else if (payloadData.action === "UPDATED_PREFERRED_CHANNEL_NAME") {
			this.emit("updatedPrefferedChannelName", payloadData);
		} else if (payloadData.action === "PRESET_NAMES") {
			this.emit("updatedPresetName", payloadData);
		}else if (payloadData.action === "SYNC_AUDIO_CONTROLS") {
			this.emit("syncAudioControls", payloadData);
		}else if(payloadData.action === "SYNC_NETWORK_QUALITY"){
			this.emit("syncNetworkQuality", payloadData);
		}else if(payloadData.action === "CLEAN_UP_SESSION"){
			this.emit("cleanUpSession", payloadData);
		}else if(payloadData.action === "metronome"){
			this.emit("metronome", payloadData);
		}
		else if (payloadData.action === "compression") {
			this.emit("compressionStatus", payloadData);
		}
	};

	onConnected = (data: any, user: UserInterface) => {
		const subscribeTo = `/public/${data?.twilioSessionId}`;
		this.websocketClientUserStatus.subscribe(subscribeTo, () => {
			this.emit("subcribed", this.websocketClientUserStatus);
		});

		let message = null;
		if (!data?.isActive) {
			message = {
				action: "start_session",
				timestamp: new Date().toISOString(),
				session_id: data?.twilioSessionId,
				created_by: user.id,
			};
			this.websocketClientUserStatus.send(
				"/app/message",
				{},
				JSON.stringify(message)
			);
		} else {
			message = {
				action: "join_session",
				timestamp: new Date().toISOString(),
				session_id: data?.twilioSessionId,
				created_by: user.id,
			};
			this.websocketClientUserStatus.send(
				"/app/message",
				{},
				JSON.stringify(message)
			);
		}
	};

	handleAudioControl(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleEmojiChange(data: any) {
		this.emit("emojiChanged", data);
		this.websocketClientUserStatus.send(
			`/public/${data.twilioSessionId}`,
			{},
			JSON.stringify(data)
		);
	}

	handleMixerAudioControl(data: any) {
		console.log("check for websocket ::", this.websocketClientUserStatus.connected);
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleSavePresetChange(data: any) {
		console.log("check for websocket ::",this.websocketClientUserStatus.connected);
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}
	handleCleanUpSession(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleRecordingControl(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleAudioCompressionControl(data: any) {
		console.log("sending message:",data );
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	} 

	handleLeaveSession(data: any) {
		this.sessionStatus = "";
		if (this.websocketClientUserStatus) {
			this.websocketClientUserStatus.send(
				"/app/message",
				{},
				JSON.stringify(data)
			);
		}
	}

	handleAudioEffect(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleBroadcasting(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleLiveStream(data: any) {
		this.liveStreamClient.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleNetworkQuality(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handleMetronome(data: any) {
		this.websocketClientUserStatus.send(
			"/app/message",
			{},
			JSON.stringify(data)
		);
	}

	handlePingDevice(message: any) {
		const data = {
			...message,
			environmentId: activeMqttClientEnv(),
		};
		 
		if (this.websocketClientUserStatus) {
			this.websocketClientUserStatus.subscribe(
				`/public/${data.created_for}`,
				this.onMessageReceived
			);
			this.websocketClientUserStatus.send(
				"/app/message",
				{},
				JSON.stringify(data)
			);
		} else {
			this.ReCreateConnectionUSerStatus(data.created_for);
			setTimeout(() => {
				this.handlePingDevice(data);
			}, 3000);
		}

		// });
	}

	onLeaveSession(payload: any, user: UserInterface) {
		this.websocketClientUserStatus.unsubscribe(
			`/public/deviceError/${payload}/${user?.nickname}`
		);
		console.log("UNSUBSCRIBING");
	}

	disconnectWebsocketConnection(payload: any, user: UserInterface) {
		this.websocketClientUserStatus.unsubscribe(
			`/public/${payload.metadata.twilioSessionId}`
		);

		this.websocketClientUserStatus.disconnect();
	}

	disconnectWebsocket() {
		this.websocketClientUserStatus.unsubscribe(`/public/livestreamstatus`);

		this.websocketClientUserStatus.disconnect();
	}

	isWebsocketClientConnected = () => {
		return this.websocketClientUserStatus?.isConnected || false;
	};
}

export default new WebsocketClientUserStatus();
